import React from "react";
import { Link } from "react-router-dom";
import { FaCaretRight } from "react-icons/fa6";

export default function HostelsGender() {
  document.title = "Hostels";

  return (
    <>
      <div className="form-header" style={{ marginBottom: "0" }}>
        <h1>Hostels</h1>
      </div>
      <div className="departments-wrapper">
        <div>
          <span>Male</span>
          <Link to="M">
            <FaCaretRight />
          </Link>
        </div>
        <div>
          <span>Female</span>
          <Link to="F">
            <FaCaretRight />
          </Link>
        </div>
      </div>
    </>
  );
}
