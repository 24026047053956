import React, { useEffect, useState } from "react";
import { LiaTimesCircle } from "react-icons/lia";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import { deleteStudent, showSwal, stripSpace } from "../AppManager";
import { FaSpinner } from "react-icons/fa6";
import { generalURL } from "../Auth";

export default function Delete() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({ key: "" });
  const [keyword, setKeyword] = useState("loading...");
  const [member, setMember] = useState({
    name: "",
    department: "",
    level: "",
    image: "",
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const { matric } = useParams();

  useEffect(() => {
    if (
      state &&
      state.permission &&
      state.member &&
      state.id &&
      state.member.idnum === state.id &&
      matric === state.id
    ) {
      setMember(state.member);
    } else {
      navigate("/delete");
    }
  }, [navigate, state, matric]);

  useEffect(() => {
    const trimname = stripSpace(member.name);
    member.name &&
      setKeyword(
        trimname.length >= 10
          ? trimname.slice(0, 10)
          : trimname.padEnd("10", nanoid(10 - trimname.length))
      );
    document.title = "Delete Student";
  }, [member]);

  function handleChange(e) {
    const { name, value } = e.target;
    setDisabled(value !== keyword);
    setFormData((oldData) => ({ ...oldData, [name]: value.slice(0, 10) }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);
      if (formData.key === keyword) {
        const res = await deleteStudent(member["matric"]);
        if (res === "Deleted") {
          navigate("/delete", { replace: true });
          showSwal("success", "Student Deleted");
        } else if (res === "Not Found") {
          showSwal("error", "Student not Found");
          navigate("/delete", { replace: true });
        } else {
          setTimeout(() => {
            setStatus({
              status: "error",
              type: "failed",
              message: "Delete Failed",
            });
            setLoading(false);
          }, 1000);
        }
      } else {
        setTimeout(() => {
          setStatus({
            status: "error",
            type: "error",
            message: "Incorrect Key",
          });
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Delete Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  return (
    <>
      <div className="form-header overflow-y">
        <h1>Delete Student</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="edit-group">
            <div>
              <h5 className="text-center fw-bolder">Student Details</h5>
            </div>
            <div className="edit-details">
              <div className="imgwrap">
                <img
                  onError={(e) => {
                    if (e.target.src !== "/user.png") {
                      e.target.onerror = null;
                      e.target.src = "/user.png";
                    }
                  }}
                  className="img"
                  src={`${generalURL}/images/students/${member.image}`}
                  alt={member.name}
                />
              </div>
              <div className="edit-details-text">
                <p>{member.name}</p>
                <p>
                  {member.gender === "M" && "Male"}
                  {member.gender === "F" && "Female"}
                </p>
                <p>{member.matric}</p>
                <p>{member.department}</p>
                <p>{member.level} Level</p>
                <p>{member.hostel}</p>
                <p>
                  {member.block}, {member.room}
                </p>
              </div>
            </div>
          </div>
          <div className="delete-message">
            <p>
              Type <strong>{keyword}</strong> below to confirm delete
            </p>
            <i>
              <strong>Note:</strong> This action is irreversible and cannot be
              undone.
            </i>
          </div>
          <div className="form-group">
            <label htmlFor="key">Key</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.idnum}
              id="key"
              name="key"
              required
              placeholder={keyword}
              minLength={10}
              maxLength={10}
            />
          </div>
          <div className="form-group">
            <button disabled={disabled || loading || keyword === "loading..."}>
              {loading ? <FaSpinner className="spinner" /> : "Confirm"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
