import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaSpinner } from "react-icons/fa6";
import { addCentre, passwordStrength } from "../AppManager";
import { LiaTimesCircle } from "react-icons/lia";
import { useNavigate, useOutletContext } from "react-router-dom";

export default function AddCentre() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ centre: "", password: "" });
  const [showp, setShowp] = useState(false);
  const [checkProgress, setCheckProgress] = React.useState({
    strength: "0%",
    color: "red",
    upper: false,
    lower: false,
    special: false,
    length: false,
    number: false,
  });
  const { centres } = useOutletContext();
  const navigate = useNavigate();

  const handleShowp = () => {
    setShowp((old) => !old);
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldFormData) => {
      let newFormData = { ...oldFormData, [name]: value };
      setCheckProgress(passwordStrength(newFormData.password));
      return newFormData;
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);
      const excluded = ["session", "time"];
      const exclude = excluded.filter(
        (cent) => formData.centre.toLowerCase() === cent.toLowerCase()
      );
      const exists = Object.keys(centres).filter(
        (cent) => formData.centre.toLowerCase() === cent.toLowerCase()
      );
      if (exists.length !== 0 || exclude.length !== 0) {
        setTimeout(() => {
          setStatus({
            status: "Exists",
            type: "failed",
            message: "Centre already Exists",
          });
          setLoading(false);
        }, 1000);
      } else {
        const res = await addCentre(formData);
        if (res === "Success") {
          setTimeout(() => {
            setFormData({ centre: "", password: "" });
            setStatus({
              status: "success",
              type: "success",
              message: "Added Successfully. Redirecting...",
            });
            setTimeout(() => {
              navigate("/centres", { replace: true });
            }, 2000);
          }, 2000);
        } else if (res === "Exists") {
          setStatus({
            status: "Exists",
            type: "failed",
            message: "Centre already Exists",
          });
          setLoading(false);
        } else {
          setTimeout(() => {
            setStatus({
              status: "error",
              type: "failed",
              message: "Failed",
            });
            setLoading(false);
          }, 1000);
        }
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
    document.title = "Add Centre";
  }, [status]);

  return (
    <>
      <div className="form-header overflow-y">
        <h1>Add Centre</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="form-group">
            <label htmlFor="centre">Centre</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.centre}
              id="centre"
              name="centre"
              required
              placeholder="Centre"
              maxLength={30}
              minLength={3}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              disabled={loading}
              type={showp ? "text" : "password"}
              onChange={handleChange}
              value={formData.password}
              id="password"
              name="password"
              required
              placeholder="Password"
              className="password-input"
            />
            {!showp && <FaEye onClick={handleShowp} className="password-eye" />}
            {showp && (
              <FaEyeSlash onClick={handleShowp} className="password-eye" />
            )}
          </div>
          <div className="form-group">
            <span className="password-note">
              <div id="strength">
                <div
                  id="strength-bar"
                  style={{
                    width: checkProgress.strength,
                    backgroundColor: checkProgress.color,
                  }}
                ></div>
              </div>
              <i>
                At Least:
                <ul>
                  <li className={checkProgress.upper ? "complete" : null}>
                    <span> 1 Upper Case</span>
                  </li>
                  <li className={checkProgress.lower ? "complete" : null}>
                    <span> 1 Lower Case</span>
                  </li>
                  <li className={checkProgress.number ? "complete" : null}>
                    <span> 1 Number</span>
                  </li>
                  <li className={checkProgress.special ? "complete" : null}>
                    <span> 1 Special Character</span>
                  </li>
                  <li className={checkProgress.length ? "complete" : null}>
                    <span> 6 Characters</span>
                  </li>
                </ul>
              </i>
            </span>
          </div>
          <div className="form-group">
            <button
              type="submit"
              disabled={
                loading ||
                (checkProgress.strength !== "0%" &&
                  checkProgress.strength !== "100%")
              }
            >
              {loading ? <FaSpinner className="spinner" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
