import React, { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { LiaTimesCircle } from "react-icons/lia";
import { FaChevronDown, FaSpinner } from "react-icons/fa6";
import DepartmentsSelection from "./DepartmentsSelection";
import { useOutletContext } from "react-router-dom";
import { updateCentreAllocations } from "../AppManager";
import HostelsSelection from "./HostelsSelection";

export default function CentreAllocations() {
  const [formData, setFormData] = useState({
    centre: "",
    display: "",
    service: "",
    type: "hostels",
    departments: "",
    hostels: "",
  });
  const [status, setStatus] = React.useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [showDepartments, setShowDepartments] = useState(false);
  const [showHostels, setShowHostels] = useState(false);
  const [centresArray, setCentresArray] = useState([]);
  const { centres } = useOutletContext();

  useEffect(() => {
    setCentresArray(Object.keys(centres).sort());
  }, [centres]);

  document.title = "Centre Allocations";

  const handleShowDepartments = () => {
    setShowDepartments((old) => !old);
  };

  const handleShowHostels = () => {
    setShowHostels((old) => !old);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((old) => ({
      ...old,
      service: name === "centre" ? "1st Service" : old.service,
      [formData.type]:
        name === "centre"
          ? JSON.parse(centres[value][old.type])["1st Service"]
          : name === "service" && old.centre
          ? JSON.parse(centres[old.centre][old.type])[value]
          : old[old.type]["1st Service"],
      display:
        name === "centre"
          ? JSON.parse(centres[value][old.type])
              ["1st Service"].slice(0, 3)
              .join(", ")
          : name === "service" && old.centre
          ? JSON.parse(centres[old.centre][old.type])
              [value].slice(0, 3)
              .join(", ")
          : old.display,
      [name]: value,
    }));
  };

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setStatus({ status: "", type: "", message: "" });
      setLoading(true);
      const data = {
        ...formData,
        [formData.type]: {
          ...JSON.parse(centres[formData.centre][formData.type]),
          [formData.service]: formData[formData.type],
        },
        allocation: {
          ...JSON.parse(centres[formData.centre].allocation),
          [formData.service]: formData.type,
        },
      };
      data[formData.type] = JSON.stringify(data[formData.type]);
      data.allocation = JSON.stringify(data.allocation);

      const update = await updateCentreAllocations(data);

      setTimeout(() => {
        if (update === "Updated") {
          setStatus({
            status: "success",
            type: "success",
            message: "Updated Successfully",
          });
          setFormData({
            centre: "",
            display: "",
            service: "",
            type: formData.type,
            departments: "",
            hostels: "",
          });
        } else {
          setStatus({
            status: "error",
            type: "failed",
            message: "Update Failed",
          });
        }
        setLoading(false);
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Update Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  const centresArrayElement = centresArray.map((centre) => (
    <option value={centre} key={nanoid()}>
      {centre}
    </option>
  ));

  return (
    <>
      {showDepartments && (
        <DepartmentsSelection
          setFormData={setFormData}
          close={() => {
            setShowDepartments(false);
          }}
          prevSelection={formData.departments}
        />
      )}
      {showHostels && (
        <HostelsSelection
          setFormData={setFormData}
          close={() => {
            setShowHostels(false);
          }}
          prevSelection={formData.hostels}
        />
      )}
      <div className="form-header overflow-y">
        <h1>Centre Allocations</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="form-group">
            <label htmlFor="centre">Worship Centre</label>
            <select
              disabled={loading}
              id="centre"
              onChange={handleChange}
              value={formData.centre}
              name="centre"
              required
            >
              <option disabled value="">
                Choose one
              </option>
              {centresArrayElement}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="service">Service</label>
            <select
              disabled={loading}
              id="service"
              onChange={handleChange}
              value={formData.service}
              name="service"
              required
              autoComplete="off"
            >
              <option disabled value="">
                Choose one
              </option>
              <option value="1st Service">1st Service</option>
              <option value="2nd Service">2nd Service</option>
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="display">
              {formData.type === "departments" ? "Departments" : "Hostels"}
            </label>
            <input
              type="text"
              placeholder={
                formData.type === "departments" ? "Departments" : "Hostels"
              }
              id="display"
              value={formData.display}
              name="display"
              onClick={
                loading
                  ? null
                  : formData.type === "departments"
                  ? handleShowDepartments
                  : handleShowHostels
              }
              disabled={loading}
              readOnly
              autoComplete="off"
              required
              style={{ cursor: "default" }}
            />
            <FaChevronDown className="select-icon" />
          </div>
          <div
            style={{
              marginTop: "-15px",
              marginBottom: "10px",
              cursor: "pointer",
              textDecoration: "underline",
              color: "#072a46",
            }}
          >
            {formData.type === "hostels" && (
              <span
                onClick={() =>
                  setFormData((old) => ({
                    ...old,
                    type: "departments",
                    display: "",
                  }))
                }
              >
                Departments
              </span>
            )}
            {formData.type === "departments" && (
              <span
                onClick={() =>
                  setFormData((old) => ({
                    ...old,
                    type: "hostels",
                    display: "",
                  }))
                }
              >
                Hostels
              </span>
            )}
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinner" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
