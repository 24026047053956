import React, { useEffect, useState } from "react";
import {
  FaArrowsRotate,
  FaCheckDouble,
  FaChevronDown,
  FaClock,
  FaCopy,
  FaPaperPlane,
  FaSpinner,
  FaTriangleExclamation,
} from "react-icons/fa6";
import { FaRandom } from "react-icons/fa";
import { LiaTimesCircle } from "react-icons/lia";
import { useParams } from "react-router-dom";
import { passwordGenerator, randomPassword, tokenRequestRandom } from "../Auth";
import { nanoid } from "nanoid";
import CentresSelection from "./CentresSelection";

export default function PasswordsRandom() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [request, setRequest] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [formData, setFormData] = useState({
    token: "",
    centres: [],
    display: "",
    passwords: {},
    password: "",
  });
  const [showp, setShowp] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    if (token && token.length === 10) {
      setFormData((old) => ({ ...old, token }));
    }
  }, [token]);

  const handleShowp = () => {
    setShowp((old) => !old);
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldFormData) => ({ ...oldFormData, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      if (formData.centres.length === 0) {
        setStatus({
          status: "failed",
          type: "",
          message: "Choose Centres First",
        });
        return;
      }
      if (Object.keys(formData.passwords).length === 0) {
        setStatus({
          status: "failed",
          type: "",
          message: "Generate Passwords First",
        });
        return;
      }
      setLoading(true);
      const data = {
        passwords: JSON.stringify(formData.passwords),
        token: formData.token,
        password: formData.password,
      };
      const reset = await randomPassword(data);

      setLoading(false);
      if (reset === "Success") {
        setStatus({
          status: "success",
          type: "success",
          message: "Password Reset Successful",
        });
        setFormData({
          token: "",
          centres: [],
          display: "",
          passwords: formData.passwords,
          password: "",
        });
      } else if (reset === "Password") {
        setStatus({
          status: "password",
          type: "failed",
          message: "Incorrect Password",
        });
      } else if (reset === "Expired") {
        setStatus({
          status: "Expired",
          type: "failed",
          message: "Token Expired",
        });
      } else {
        setStatus({
          status: "error",
          type: "failed",
          message: "Password Reset Failed",
        });
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Password Reset Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  const handleCopy = (centre, password) => {
    navigator.clipboard
      .writeText(`${centre}: ${password}`)
      .then(() => {
        setCopied(centre);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {});
  };

  const handleCopyAll = () => {
    let textToCopy = "*Centres Password*\n";
    textToCopy += Object.keys(formData.passwords)
      .filter(
        (centre) => centre.split(" ").pop().toLowerCase().trim() !== "workers"
      )
      .sort()
      .map(
        (centre, index) =>
          `${index === 0 ? "*General*\n" : ""}*${centre}*: ${
            formData.passwords[centre]
          }`
      )
      .join("\n");
    textToCopy += Object.keys(formData.passwords)
      .filter(
        (centre) => centre.split(" ").pop().toLowerCase().trim() === "workers"
      )
      .sort()
      .map(
        (centre, index) =>
          `${index === 0 ? "\n\n*Workers*\n" : ""}*${centre.replace(
            " Workers",
            ""
          )}*: ${formData.passwords[centre]}`
      )
      .join("\n");
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied("All");
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {});
  };

  const generate = (centre) => {
    const password = passwordGenerator(centre);
    setGenerating(centre);
    setTimeout(() => {
      setFormData((old) => ({
        ...old,
        passwords: { ...old.passwords, [centre]: password },
      }));
      setGenerating(false);
    }, 1000);
  };

  const generateAll = () => {
    const passwords = {};
    setGenerating("All");
    const centres = formData.centres.sort();
    for (let p = 0; p < centres.length; p++) {
      const centre = centres[p];
      const password = passwordGenerator(centre);
      passwords[centre] = password;
    }
    setTimeout(() => {
      setFormData((old) => ({
        ...old,
        passwords,
      }));
      setGenerating(false);
    }, 1000);
  };

  async function handleToken(e) {
    e.preventDefault();
    try {
      if (formData.centres.length !== 0) {
        setRequesting(true);
        const send = await tokenRequestRandom({
          centres: JSON.stringify(formData.centres),
        });

        setRequesting(send);
        setTimeout(() => {
          setRequesting(false);
          send === "Success" && setRequest(false);
        }, 2000);
      }
    } catch (error) {
      setRequesting("Failed");
      setTimeout(() => {
        setRequesting(false);
        setRequest(false);
      }, 2000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  document.title = "Random Passwords";

  return (
    <>
      {showp && (
        <CentresSelection
          setFormData={setFormData}
          close={() => {
            setShowp(false);
            setRequest(true);
            setFormData((old) => ({ ...old, passwords: {} }));
          }}
          prevSelection={formData.centres}
        />
      )}
      <div className="form-header overflow-y">
        <h1>Random Passwords</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="form-group">
            <label htmlFor="display">Worship Centre</label>
            <input
              type="text"
              placeholder="Worship Centre"
              id="display"
              value={formData.display}
              name="display"
              onClick={loading || requesting ? null : handleShowp}
              disabled={loading || requesting}
              readOnly
              autoComplete="off"
              required
              style={{ cursor: "default" }}
            />
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              disabled={loading}
              type="password"
              onChange={handleChange}
              value={formData.password}
              id="password"
              name="password"
              required
              placeholder={"Password"}
            />
          </div>
          {formData.centres.length !== 0 && request && (
            <div className="form-group">
              <p className="status-wrapper request-token" id="status-wrapper">
                <span className={`status-message`}>
                  Request Token?{" "}
                  <button
                    onClick={handleToken}
                    disabled={requesting}
                    style={{
                      backgroundColor: requesting
                        ? requesting === true
                          ? "gray"
                          : requesting === "Success"
                          ? "green"
                          : requesting === "Exists"
                          ? "orange"
                          : "red"
                        : "#072a46",
                    }}
                  >
                    {requesting ? (
                      requesting === true ? (
                        <FaSpinner className="spinner" />
                      ) : requesting === "Success" ? (
                        <FaCheckDouble />
                      ) : requesting === "Exists" ? (
                        <FaClock />
                      ) : (
                        <FaTriangleExclamation />
                      )
                    ) : (
                      <FaPaperPlane />
                    )}
                  </button>
                </span>
                <LiaTimesCircle
                  className="status-close"
                  onClick={() => {
                    setRequest(false);
                  }}
                />
              </p>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="token">Token</label>
            <input
              disabled={loading}
              type="text"
              value={formData.token}
              onChange={handleChange}
              id="token"
              name="token"
              required
              autoComplete="off"
              style={{ letterSpacing: "10px" }}
              minLength={10}
              maxLength={10}
              placeholder="Token"
            />
          </div>
          <div className="student-upload-text">
            <strong style={{ fontSize: "1.1em" }}>Passwords</strong>
            <br />
            {Object.keys(formData.passwords)
              .filter(
                (centre) =>
                  centre.split(" ").pop().toLowerCase().trim() !== "workers"
              )
              .sort()
              .map((centre, index) => (
                <span key={nanoid()}>
                  {index === 0 && <strong>General</strong>}
                  <br />
                  <span>
                    <strong>{centre}: &nbsp;</strong>
                    <span>{formData.passwords[centre]}</span>{" "}
                    {generating !== centre && (
                      <FaArrowsRotate
                        title="Regenerate"
                        style={{
                          fontSize: "0.9em",
                          marginTop: "-2px",
                          cursor: "pointer",
                        }}
                        onClick={() => !generating && generate(centre)}
                      />
                    )}
                    {generating === centre && (
                      <FaSpinner
                        className="spinner"
                        style={{
                          fontSize: "0.9em",
                          marginTop: "-2px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                    &nbsp;&nbsp;
                    {copied !== centre && (
                      <FaCopy
                        title="Copy"
                        style={{
                          fontSize: "0.9em",
                          marginTop: "-2px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          !copied &&
                          handleCopy(centre, formData.passwords[centre])
                        }
                      />
                    )}
                    {copied === centre && (
                      <FaCheckDouble
                        style={{
                          fontSize: "0.9em",
                          marginTop: "-2px",
                          marginRight: "2px",
                          color: "green",
                        }}
                      />
                    )}
                  </span>
                </span>
              ))}
            {Object.keys(formData.passwords)
              .filter(
                (centre) =>
                  centre.split(" ").pop().toLowerCase().trim() === "workers"
              )
              .sort()
              .map((centre, index) => (
                <span key={nanoid()}>
                  {index === 0 && (
                    <strong>
                      <br />
                      <br />
                      Workers
                    </strong>
                  )}
                  <br />
                  <span>
                    <strong>{centre.replace(" Workers", "")}: &nbsp;</strong>
                    <span>{formData.passwords[centre]}</span>{" "}
                    {generating !== centre && (
                      <FaArrowsRotate
                        title="Regenerate"
                        style={{
                          fontSize: "0.9em",
                          marginTop: "-2px",
                          cursor: "pointer",
                        }}
                        onClick={() => !generating && generate(centre)}
                      />
                    )}
                    {generating === centre && (
                      <FaSpinner
                        className="spinner"
                        style={{
                          fontSize: "0.9em",
                          marginTop: "-2px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                    &nbsp;&nbsp;
                    {copied !== centre && (
                      <FaCopy
                        title="Copy"
                        style={{
                          fontSize: "0.9em",
                          marginTop: "-2px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          !copied &&
                          handleCopy(centre, formData.passwords[centre])
                        }
                      />
                    )}
                    {copied === centre && (
                      <FaCheckDouble
                        style={{
                          fontSize: "0.9em",
                          marginTop: "-2px",
                          marginRight: "2px",
                          color: "green",
                        }}
                      />
                    )}
                  </span>
                </span>
              ))}
            {Object.keys(formData.passwords).length !== 0 && (
              <span
                className={`student-upload-copy`}
                style={{ right: "initial", left: "8px", fontSize: "0.9em" }}
                title="Copy"
              >
                {copied !== "All" && (
                  <span
                    onClick={handleCopyAll}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    <FaCopy
                      style={{
                        fontSize: "0.9em",
                        marginTop: "-2px",
                        marginRight: "2px",
                      }}
                    />
                    Copy
                  </span>
                )}
                {copied === "All" && (
                  <span
                    style={{
                      cursor: "default",
                      color: "green",
                    }}
                  >
                    <FaCheckDouble
                      style={{
                        fontSize: "0.9em",
                        marginTop: "-2px",
                        marginRight: "2px",
                      }}
                    />
                    Copied
                  </span>
                )}
              </span>
            )}
            {formData.centres.length !== 0 && (
              <span
                className={`student-upload-copy`}
                style={{ fontSize: "0.9em", textDecoration: "underline" }}
                title="Generate"
                onClick={!generating ? generateAll : null}
              >
                {generating !== "All" && (
                  <span>
                    <FaRandom
                      style={{ fontSize: "0.9em", marginTop: "-2px" }}
                    />{" "}
                    Generate
                  </span>
                )}
                {generating === "All" && (
                  <span>
                    <FaSpinner
                      className="spinner"
                      style={{ fontSize: "0.9em", marginTop: "-2px" }}
                    />{" "}
                    Generating
                  </span>
                )}
              </span>
            )}
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinner" /> : "Set"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
