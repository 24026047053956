import React, { useEffect, useState } from "react";
import { FaChevronDown, FaSpinner } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import { createAverage, getMonths } from "../AppManager";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import { downloadBaseURL } from "../Auth";

export default function AttendanceAverageInput() {
  const [formData, setFormData] = useState({ start: "", end: "" });
  const [list, setList] = useState([]);
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getMonths()
      .then((data) => {
        setList(data.reverse());
        // setLoading(false);
      })
      .catch((err) => err);
  }, []);

  document.title = "Average Range";

  function isDateGreater(date1, compare) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const [month1, year1] = date1.split(" ");
    const [month2, year2] = compare.split(" ");
    const monthIndex1 = months.indexOf(month1);
    const monthIndex2 = months.indexOf(month2);
    const yearNum1 = parseInt(year1, 10);
    const yearNum2 = parseInt(year2, 10);
    if (yearNum1 > yearNum2) {
      return true;
    } else if (yearNum1 === yearNum2) {
      return monthIndex1 > monthIndex2;
    } else {
      return false;
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((old) => ({ ...old, [name]: value }));
  };

  const pollProgress = (uniqueId) => {
    fetch(`${downloadBaseURL}${uniqueId}.json`)
      .then((res) => res.json())
      .then(({ progress }) => {
        if (progress) {
          setProgress(progress);
          // Stop polling if progress is 100%
          if (progress >= 100) {
          } else {
            setTimeout(() => pollProgress(uniqueId), 500); // Poll again in 0.5 seconds
          }
        } else {
          if (!loading) {
            // Handle if needed
          } else {
            setTimeout(() => pollProgress(uniqueId), 500); // Poll again in 0.5 seconds
          }
        }
      })
      .catch((error) => {
        if (!loading) {
        } else {
          setTimeout(() => pollProgress(uniqueId), 500); // Poll again in 0.5 seconds
        }
      });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);
      setProgress(0);
      const json = nanoid();
      pollProgress(json);
      const res = await createAverage({ ...formData, json });

      if (res === "Success") {
        setStatus({
          status: "success",
          type: "success",
          message: "Generated Successful. Redirecting...",
        });
        setFormData({ start: "", end: "" });
        navigate(`${formData.start} - ${formData.end}`);
      } else {
        setStatus({
          status: "error",
          type: "failed",
          message: "Generation Failed",
        });
        setLoading(false);
      }
    } catch (error) {
      setStatus({
        status: "error",
        type: "failed",
        message: "Generation Failed",
      });
      setLoading(false);
    }
  }

  const listElements = list.slice(0, list.length - 1).map((month) => (
    <option value={month} key={nanoid()}>
      {month}
    </option>
  ));

  const endListElements = list
    .filter((month) => isDateGreater(month, formData.start))
    .slice(0, 5)
    .map((month) => (
      <option value={month} key={nanoid()}>
        {month}
      </option>
    ));

  return (
    <>
      <div className="form-header">
        <h1>Average Range</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="form-group">
            <label htmlFor="start">Start</label>
            <select
              disabled={loading}
              id="start"
              onChange={handleChange}
              value={formData.start}
              name="start"
              required
            >
              <option disabled value="">
                Choose one
              </option>
              {listElements}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="end">End</label>
            <select
              disabled={loading}
              id="end"
              onChange={handleChange}
              value={formData.end}
              name="end"
              required
            >
              <option disabled value="">
                Choose one
              </option>
              {endListElements}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinner" /> : "submit"}
            </button>
            {loading && progress !== 0 && (
              <div className="upload-status">
                <div id="upload-progress">
                  <div
                    id="upload-progress-bar"
                    className="loader-item"
                    style={{
                      width: progress + "%",
                      backgroundImage:
                        progress !== 100
                          ? "linear-gradient(110deg, #072a46 8%, #1257a7 18%, #072a46 33%)"
                          : "linear-gradient(110deg, green 8%, yellowgreen 18%, green 33%)",
                    }}
                  ></div>
                  <label>{Math.floor(progress)}%</label>
                </div>
              </div>
            )}
            {loading && (
              <p style={{ margin: "auto" }}>
                {progress === 100
                  ? "Finishing..."
                  : "This may take a while, please wait..."}
              </p>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
