import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { nanoid } from "nanoid";
import { FaMagnifyingGlass, FaPlus, FaTrash } from "react-icons/fa6";
import { debounce } from "../AppManager";

export default function Centres() {
  const { centres } = useOutletContext();
  const centresKey = Object.keys(centres).sort();

  const [search, setSearch] = useState("");

  useEffect(() => {
    const outlet = document.getElementsByClassName("outlet")[0];
    const formHeader = document.getElementsByClassName("form-header")[0];
    const departmentsWrapper = document.getElementsByClassName(
      "departments-wrapper"
    )[0];
    const searchSpecial = document.getElementsByClassName(
      "form-group search-input"
    )[0];
    outlet.style.height = "75vh";

    function height() {
      const total = outlet.clientHeight - 80;
      const search = searchSpecial ? searchSpecial.clientHeight : 0;
      const header = formHeader ? formHeader.clientHeight : 0;
      const left = total - search - header;
      if (departmentsWrapper.clientHeight > left && departmentsWrapper) {
        departmentsWrapper.style.height = left + "px";
      } else {
        outlet.style.removeProperty("height");
      }
    }

    const debouncedHeight = debounce(height, 100);

    height();
    const resizeObserver = new ResizeObserver(debouncedHeight);
    resizeObserver.observe(outlet);
    resizeObserver.observe(departmentsWrapper);
    resizeObserver.observe(formHeader);

    return () => {
      resizeObserver.disconnect();
      outlet.style.removeProperty("height");
      clearTimeout(debouncedHeight.timeout);
    };
  }, []);

  function handleSearch(e) {
    function cleanString(input) {
      // Regular expression to match only letters (both cases), numbers, and spaces
      const regex = /[^a-zA-Z0-9 ]/g;
      // Replace any character that is not a letter, number, or space with an empty string
      return input.replace(regex, "");
    }

    setSearch(cleanString(e.target.value));
  }

  const centresKeyElement = centresKey
    .filter(
      (centre) => centre.toLowerCase().indexOf(search.toLowerCase()) !== -1
    )
    .map((centre) => (
      <div key={nanoid()}>
        <span>{centre}</span>
        <Link to={`delete/${centre}`}>
          <FaTrash />
        </Link>
      </div>
    ));
  document.title = "Centres";

  return (
    <>
      <div className="form-header" style={{ marginBottom: "0" }}>
        <h1>Centres</h1>
      </div>
      {centres.length > 5 && (
        <div
          className="form-group search-input"
          style={{ marginBottom: "5px", marginTop: 0 }}
        >
          <FaMagnifyingGlass />
          <input
            type="text"
            id="key"
            name="key"
            required
            value={search}
            onChange={handleSearch}
            maxLength={20}
            placeholder="Search"
          />
        </div>
      )}
      <div className="departments-wrapper">
        <div>
          <span>Add Centre</span>
          <Link to={"add"}>
            <FaPlus />
          </Link>
        </div>
        {centresKeyElement}
      </div>
    </>
  );
}
