import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Link, useParams } from "react-router-dom";
import {
  generateAnalysis,
  generateAttendance,
  getMonthStatistics,
  showSwal,
} from "../AppManager";
import { downloadBaseURL } from "../Auth";
import { FaSpinner } from "react-icons/fa6";

Chart.register(ArcElement, Tooltip, Legend, Title);

export default function Attendance() {
  const [chartData, setChartData] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [stats, setStats] = useState({ total: 0, present: 0, absent: 0 });
  const [download, setDownload] = useState({ state: "", link: "" });
  const [analysis, setAnalysis] = useState({ state: "", link: "" });
  const { month } = useParams();

  useEffect(() => {
    getMonthStatistics(month)
      .then((data) => {
        let total = 100;
        let present = parseFloat(data.replace("%", ""));
        setStats({ total, present, absent: total - present });
      })
      .catch((err) => setStats({ total: 1, present: 1, absent: 0 }));
    document.title = month.toUpperCase() + " Attendance";
  }, [month]);

  useEffect(() => {
    const Data = [
      {
        id: 1,
        numbers: stats.present,
      },
      {
        id: 2,
        numbers: stats.absent,
      },
    ];

    const chartDat = {
      labels: ["Present", "Absent"],
      datasets: [
        {
          label: "Statistics",
          data: Data.map((data) => data.numbers),
          backgroundColor: [
            // "#072A46",
            "#FFC436",
            "darkred",
          ],
          // borderColor: "black",
          // borderWidth: 2
        },
      ],
    };

    setChartData(chartDat);
  }, [stats]);

  const onHover = (event, chartElement) => {
    if (chartElement.length) {
      setActiveIndex(chartElement[0].index);
    } else {
      setActiveIndex(null);
    }
  };

  const customChartData = {
    ...chartData,
    datasets: chartData.datasets
      ? chartData.datasets.map((dataset) => ({
          ...dataset,
          backgroundColor: dataset.backgroundColor.map((color, index) => {
            if (activeIndex !== null && index !== activeIndex) {
              return "rgba(0, 0, 0, 0.1)";
            }
            return color;
          }),
          borderColor: dataset.backgroundColor.map((color, index) => {
            if (activeIndex !== null && index !== activeIndex) {
              return "rgba(255, 255, 255, 0.1)";
            }
            return "white";
          }),
        }))
      : [],
  };

  function downloadAttendance() {
    setDownload({ state: "generating", link: "" });
    generateAttendance(month)
      .then((filename) => {
        if (filename === "Empty") {
          showSwal("error", "Attendance Download Failed", "No Record Found");
          setDownload({ state: "", link: "" });
        } else if (filename === "Failed") {
          showSwal("error", "Attendance Download Failed");
          setDownload({ state: "", link: "" });
        } else if (filename.indexOf(".xlsx") !== -1) {
          setTimeout(() => {
            setDownload({
              state: "ready",
              link: `${downloadBaseURL}${filename}`,
            });
          }, 1000);
        } else {
          showSwal("error", "Attendance Download Failed");
          setDownload({ state: "", link: "" });
        }
      })
      .catch((err) => {
        showSwal("error", "Attendance Download Failed");
        setDownload({ state: "", link: "" });
      });
  }

  function downloadAnalysis() {
    setAnalysis({ state: "generating", link: "" });
    generateAnalysis(month)
      .then((filename) => {
        if (filename === "Empty") {
          showSwal("error", "Analysis Download Failed", "No Record Found");
          setAnalysis({ state: "", link: "" });
        } else if (filename === "Failed") {
          showSwal("error", "Analysis Download Failed");
          setAnalysis({ state: "", link: "" });
        } else if (filename.indexOf(".xlsx") !== -1) {
          setTimeout(() => {
            setAnalysis({
              state: "ready",
              link: `${downloadBaseURL}${filename}`,
            });
          }, 1000);
        } else {
          showSwal("error", "Analysis Download Failed");
          setAnalysis({ state: "", link: "" });
        }
      })
      .catch((err) => {
        showSwal("error", "Analysis Download Failed");
        setAnalysis({ state: "", link: "" });
      });
  }

  return (
    <>
      <div className="form-header overflow-y">
        <h1>{month} Attendance</h1>
      </div>
      <div className="form-content">
        <div style={{ display: "flex", justifyContent: "center" }}>
          {download.state === "ready" ? (
            <a
              download
              href={download.link}
              onClick={() => {
                setDownload({ state: "", link: "" });
              }}
              className={`attendance-download ${
                download.state === "ready" ? "active" : ""
              }`}
            >
              <img
                src="/images/excel.png"
                alt={`${month.toUpperCase()} Attendance`}
              />
              <label>Download</label>
            </a>
          ) : (
            <span
              className="attendance-download"
              onClick={download.state === "" ? downloadAttendance : undefined}
            >
              <img
                src="/images/excel.png"
                alt={`${month.toUpperCase()} Attendance`}
              />
              <label style={{ width: "100%" }}>
                {download.state === "" ? (
                  `${month.toUpperCase()} Attendance`
                ) : (
                  <FaSpinner
                    style={{ fontSize: "1.2em" }}
                    className="spinner"
                  />
                )}
              </label>
            </span>
          )}
          {analysis.state === "ready" ? (
            <a
              download
              href={analysis.link}
              onClick={() => {
                setAnalysis({ state: "", link: "" });
              }}
              className={`attendance-download ${
                analysis.state === "ready" ? "active" : ""
              }`}
            >
              <img
                src="/images/analysis.png"
                style={{
                  padding: "3px",
                  backgroundColor: "white",
                }}
                alt="Analysis"
              />
              <label>Download</label>
            </a>
          ) : (
            <span
              className="attendance-download"
              onClick={analysis.state === "" ? downloadAnalysis : undefined}
            >
              <img
                src="/images/analysis.png"
                style={{
                  padding: "3px",
                  backgroundColor: "white",
                }}
                alt="Analysis"
              />
              <label style={{ width: "100%" }}>
                {analysis.state === "" ? (
                  "Analysis"
                ) : (
                  <FaSpinner
                    style={{ fontSize: "1.2em" }}
                    className="spinner"
                  />
                )}
              </label>
            </span>
          )}
          <Link to={"view"} className="attendance-download">
            <img src="/images/icons/view.png" alt="View" />
            <label>View Student</label>
          </Link>
        </div>
        <form className="stats-pie attendance-pie">
          <Pie
            data={customChartData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "bottom",
                  labels: {
                    color: "#072A46",
                  },
                },
                title: {
                  display: false,
                  text: "Total Students: 100",
                  color: "#072A46",
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      const value = context.raw;
                      const total = context.dataset.data.reduce(
                        (acc, curr) => acc + curr,
                        0
                      );
                      const percentage = ((value / total) * 100).toFixed(2);
                      return ` ${percentage}%`;
                    },
                  },
                },
              },
              onHover: onHover,
            }}
          />
        </form>
      </div>
    </>
  );
}
