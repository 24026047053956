import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { nanoid } from "nanoid";
import { LiaTimesCircle } from "react-icons/lia";
import {
  FaCheckDouble,
  FaCircleQuestion,
  FaMagnifyingGlass,
  FaTrash,
} from "react-icons/fa6";
import { debounce, filterObject, rangeStringToArray } from "../AppManager";

export default function HostelsSelection({
  setFormData,
  close,
  prevSelection,
}) {
  const [specialData, setSpecialData] = useState({});
  const [info, setInfo] = useState(false);
  const { hostels } = useOutletContext();
  const [search, setSearch] = useState("");

  let hosts = hostels
    .filter(({ hostel, gender, block, brange }) => {
      const sear = search.toLowerCase().trim();
      const msearch =
        sear === "male" && gender.toLowerCase().indexOf("m") !== -1;
      const fsearch =
        sear === "female" && gender.toLowerCase().indexOf("f") !== -1;
      return (
        hostel.toLowerCase().indexOf(sear) !== -1 ||
        gender.toLowerCase().indexOf(sear) !== -1 ||
        block.toLowerCase().indexOf(sear) !== -1 ||
        brange.toLowerCase().indexOf(sear) !== -1 ||
        msearch ||
        fsearch
      );
    })
    .map(({ hostel }) => hostel);
  hosts = Array.from(new Set(hosts)).sort();

  const uniqueHostels = [];
  hosts.map((hos) => {
    const h = hostels.filter(({ hostel }) => hostel === hos);
    const { hostel, gender } = h[0];
    uniqueHostels.push({ hostel, gender });
    return hos;
  });

  const hostelBlocks = {};
  uniqueHostels.map(({ hostel, gender }) => {
    const nam = `${hostel} (${gender})`;
    hostelBlocks[nam] = [];
    hostels
      .filter((ho) => ho.hostel === hostel && ho.gender === gender)
      .map((ho) => {
        hostelBlocks[nam] = [
          ...hostelBlocks[nam],
          `${ho.block} = ${ho.brange}`,
        ];
        return ho;
      });
    hostelBlocks[nam] = Array.from(new Set(hostelBlocks[nam])).sort();
    return gender;
  });

  useEffect(() => {
    if (typeof prevSelection === "object" && prevSelection.length !== 0) {
      const prev = {};
      for (let k = 0; k < prevSelection.length; k++) {
        const selected = prevSelection[k];
        prev[selected] = true;
      }
      setSpecialData(prev);
    }
  }, [prevSelection]);

  useEffect(() => {
    const outlet = document.getElementsByClassName("outlet")[0];
    const selectionBox = document.getElementsByClassName("selection-box")[0];
    const specialSelect = document.getElementsByClassName("special-select")[0];
    const searchSpecial = document.getElementsByClassName(
      "form-group search-input"
    )[0];
    outlet.style.height = "75vh";
    outlet.style.overflowY = "hidden";
    outlet.scrollTo({ top: 0, behavior: "instant" });

    function height() {
      const total = outlet.clientHeight - 80;
      const search = searchSpecial ? searchSpecial.clientHeight : 0;
      const selection = selectionBox ? selectionBox.clientHeight : 0;
      const left = total - search - selection;
      if (specialSelect) specialSelect.style.height = left + "px";
    }

    const debouncedHeight = debounce(height, 100);

    height();
    const resizeObserver = new ResizeObserver(debouncedHeight);
    resizeObserver.observe(outlet);
    selectionBox && resizeObserver.observe(selectionBox);
    resizeObserver.observe(specialSelect);

    return () => {
      resizeObserver.disconnect();
      clearTimeout(debouncedHeight.timeout);
      outlet.style.removeProperty("height");
      outlet.style.removeProperty("overflow-y");
    };
  }, []);

  function handleSearch(e) {
    function cleanString(input) {
      // Regular expression to match only letters (both cases), numbers, and spaces
      const regex = /[^a-zA-Z0-9 ]/g;
      // Replace any character that is not a letter, number, or space with an empty string
      return input.replace(regex, "");
    }

    setSearch(cleanString(e.target.value));
  }

  function handleChange(e) {
    const { name, checked } = e.target;
    setSpecialData((old) => ({ ...old, [name]: checked }));
  }

  function shortText(select) {
    let displayArr = select.split(" ");
    let display;

    if (!isNaN(parseInt(displayArr.slice().pop()))) {
      const [hostel, block] = select.split(") ");
      const parts = block.split(" ");
      const num = parts.pop();
      const text = parts.map((part) => part.slice(0, 2)).join(" ");
      const hos = hostel
        .replace(" (", "")
        .split(" ")
        .map((part) => part.slice(0, 1))
        .join("");

      display = hos.toUpperCase() + " - " + text + " " + num;
    } else if (displayArr.pop().indexOf("(") !== -1) {
      if (displayArr.length < 3) {
        display = `${displayArr[0].slice(0, 3)} ${displayArr[1].slice(
          0,
          3
        )}...`;
      } else {
        display = `${displayArr[0].slice(0, 2)} ${displayArr[1].slice(
          0,
          2
        )} ${displayArr[2].slice(0, 2)}...`;
      }
    } else {
      display = select.slice(0, 6);
    }
    return display;
  }

  const closeAction = () => {
    const allCheck = specialData["All"] ? { All: true } : specialData;
    const select = filterObject(allCheck, (value) => value);
    const selections = Object.keys(select);
    const set = selections.length !== 0;
    setFormData((old) => ({
      ...old,
      hostels: set ? selections : "",
      display: set
        ? selections
            .slice(0, 3)
            .map((select) => shortText(select))
            .join(", ")
        : "",
    }));
    close();
  };

  const selectedElements = Object.keys(
    filterObject(specialData, (value) => value)
  )
    .sort()
    .map((select) => (
      <span key={nanoid()}>
        <span style={{ width: "initial" }}>{shortText(select)}</span>
        <LiaTimesCircle
          onClick={() => {
            setSpecialData((old) => ({ ...old, [select]: false }));
          }}
        />
      </span>
    ));

  return (
    <div className="special-select-wrapper">
      <div className="special-button">
        <span className="special-close" onClick={closeAction}>
          <FaCheckDouble />
        </span>
        <span className="special-clear" onClick={() => setSpecialData({})}>
          <FaTrash />
        </span>
      </div>
      <div
        className="form-group search-input"
        style={{ marginBottom: "5px", marginTop: 0 }}
      >
        <FaMagnifyingGlass />
        <input
          type="text"
          id="key"
          name="key"
          required
          value={search}
          onChange={handleSearch}
          maxLength={20}
          placeholder="Search"
        />
      </div>
      <div
        className="selection-box"
        style={
          selectedElements.length !== 0
            ? {}
            : { height: 0, padding: 0, opacity: 0, pointerEvents: "none" }
        }
      >
        {selectedElements}
      </div>
      <div className="special-select">
        {!search && (
          <>
            <span className="special-select-section">
              <strong>Hostels</strong>
              <span>
                {uniqueHostels.map(({ hostel, gender }) => {
                  const nam = `${hostel} (${gender})`;
                  return (
                    <span key={nanoid()}>
                      <input
                        type="checkbox"
                        name={nam}
                        id={nam}
                        onChange={handleChange}
                        checked={specialData[nam]}
                      />
                      <label htmlFor={nam}>{nam}</label>
                    </span>
                  );
                })}
                <span>
                  <label>
                    <FaCircleQuestion onClick={() => setInfo((old) => !old)} />
                  </label>
                </span>
              </span>
            </span>
            {info && (
              <span
                className="special-select-section"
                style={{ position: "relative" }}
              >
                <LiaTimesCircle
                  className="status-close"
                  style={{ color: "inherit", fontSize: "1.1em", right: "5px" }}
                  onClick={() => setInfo(false)}
                />
                <li>Block {">"} Hostel</li>
              </span>
            )}
          </>
        )}
        {Object.keys(hostelBlocks).map((host) => {
          const blocks = hostelBlocks[host];
          return (
            <span className="special-select-section" key={nanoid()}>
              <strong>{host}</strong>
              <span>
                {blocks.map((blo) => {
                  const [block, range] = blo.split(" = ");
                  const rng = rangeStringToArray(range);
                  return rng.map((r) => {
                    const rnam = `${host} ${block} ${r}`;
                    return (
                      <span key={nanoid()}>
                        <input
                          type="checkbox"
                          name={rnam}
                          id={rnam}
                          onChange={handleChange}
                          checked={specialData[rnam]}
                        />
                        <label htmlFor={rnam}>
                          {block} {r}
                        </label>
                      </span>
                    );
                  });
                })}
              </span>
            </span>
          );
        })}
      </div>
    </div>
  );
}
