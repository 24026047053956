import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { nanoid } from "nanoid";
import { FaCheckDouble } from "react-icons/fa6";

export default function CentresSelection({
  setFormData,
  close,
  prevSelection,
}) {
  const [data, setData] = useState([]);
  const { centres } = useOutletContext();
  const [centresArray, setCentresArray] = useState([]);

  useEffect(() => {
    let cents = [];
    for (const cent in centres) {
      if (Object.hasOwnProperty.call(centres, cent)) {
        cents.push(cent);
        cents.push(cent + " Workers");
      }
    }
    cents.sort();
    setCentresArray(cents);
  }, [centres]);

  useEffect(() => {
    if (typeof prevSelection === "object" && prevSelection.length !== 0) {
      setData(prevSelection);
    }
  }, [prevSelection]);

  useEffect(() => {
    const outlet = document.getElementsByClassName("outlet")[0];
    outlet.style.overflowY = "hidden";
    outlet.scrollTo({ top: 0, behavior: "instant" });

    return () => {
      outlet.style.removeProperty("overflow-y");
    };
  }, []);

  function handleSpecialChange(e) {
    const { name, checked } = e.target;
    if (name === "All") {
      if (checked) {
        setData(centresArray);
      } else {
        setData([]);
      }
    } else {
      setData((old) =>
        checked ? [...old, name] : old.filter((ol) => ol !== name)
      );
    }
  }

  const closeAction = () => {
    setFormData((old) => ({
      ...old,
      centres: Array.from(new Set(data)).sort(),
      display: data.slice(0, 3).join(", "),
    }));
    close();
  };

  const selectedElements = centresArray.map((centre) => (
    <span key={nanoid()}>
      <input
        type="checkbox"
        name={centre}
        id={centre}
        onChange={handleSpecialChange}
        checked={data.includes(centre)}
      />
      <label htmlFor={centre}>{centre}</label>
    </span>
  ));

  return (
    <div className="special-select-wrapper">
      <div className="special-select" style={{ height: "auto" }}>
        <span className="special-select-section">
          <strong>Centres</strong>
          <span>
            <span>
              <input
                type="checkbox"
                name="All"
                id="All"
                onChange={handleSpecialChange}
                checked={data.length === centresArray.length}
              />
              <label htmlFor="All">All</label>
            </span>
            {selectedElements}
          </span>
        </span>
        <span
          className="special-close"
          onClick={closeAction}
          style={{
            position: "relative",
            top: "initial",
            left: "initial",
            transform: "initial",
            margin: "0 auto 5px auto",
          }}
        >
          <FaCheckDouble />
        </span>
      </div>
    </div>
  );
}
