import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { nanoid } from "nanoid";
import { LiaTimesCircle } from "react-icons/lia";
import {
  FaCheckDouble,
  FaCircleQuestion,
  FaMagnifyingGlass,
  FaTrash,
} from "react-icons/fa6";
import { debounce, filterObject, sortByProperty } from "../AppManager";

export default function DepartmentsSelection({
  setFormData,
  close,
  prevSelection,
}) {
  const [specialData, setSpecialData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [info, setInfo] = useState(false);
  const { departments, faculties } = useOutletContext();
  const [search, setSearch] = useState("");
  const genders = ["M", "F"];

  useEffect(() => {
    if (typeof prevSelection === "object" && prevSelection.length !== 0) {
      const prev = {};
      for (let k = 0; k < prevSelection.length; k++) {
        const selected = prevSelection[k];
        prev[selected] = true;
      }
      setSpecialData(prev);
    }
  }, [prevSelection]);

  useEffect(() => {
    const outlet = document.getElementsByClassName("outlet")[0];
    const selectionBox = document.getElementsByClassName("selection-box")[0];
    const specialSelect = document.getElementsByClassName("special-select")[0];
    const searchSpecial = document.getElementsByClassName(
      "form-group search-input"
    )[0];
    outlet.style.height = "75vh";
    outlet.style.overflowY = "hidden";
    outlet.scrollTo({ top: 0, behavior: "instant" });

    function height() {
      const total = outlet.clientHeight - 80;
      const search = searchSpecial ? searchSpecial.clientHeight : 0;
      const selection = selectionBox ? selectionBox.clientHeight : 0;
      const left = total - search - selection;
      if (specialSelect) specialSelect.style.height = left + "px";
    }

    const debouncedHeight = debounce(height, 100);

    height();
    const resizeObserver = new ResizeObserver(debouncedHeight);
    resizeObserver.observe(outlet);
    selectionBox && resizeObserver.observe(selectionBox);
    resizeObserver.observe(specialSelect);

    return () => {
      resizeObserver.disconnect();
      clearTimeout(debouncedHeight.timeout);
      outlet.style.removeProperty("height");
      outlet.style.removeProperty("overflow-y");
    };
  }, []);

  function handleSearch(e) {
    function cleanString(input) {
      // Regular expression to match only letters (both cases), numbers, and spaces
      const regex = /[^a-zA-Z0-9 ]/g;
      // Replace any character that is not a letter, number, or space with an empty string
      return input.replace(regex, "");
    }

    setSearch(cleanString(e.target.value));
  }

  function handleChange(e) {
    const { name, checked } = e.target;
    if (name === "All" || name === "All 2.0") {
      setDisabled(checked);
    }
    setSpecialData((old) => ({ ...old, [name]: checked }));
  }

  function specialSection({ department, level, code }) {
    const levels = [];
    for (let h = 0; h < genders.length; h++) {
      const gender = genders[h];
      for (let l = 100; l <= parseInt(level); l += 100) {
        department === "Level"
          ? levels.push(
              <span key={nanoid()}>
                <input
                  type="checkbox"
                  name={`${l} (${gender})`}
                  id={`${l} (${gender})`}
                  onChange={handleChange}
                  checked={specialData[`${l} (${gender})`]}
                  disabled={disabled}
                />
                <label htmlFor={`${l} (${gender})`}>
                  {l} ({gender})
                </label>
              </span>
            )
          : levels.push(
              <span key={nanoid()}>
                <input
                  type="checkbox"
                  name={`${code} - ${l} (${gender})`}
                  id={`${code} - ${l} (${gender})`}
                  onChange={handleChange}
                  checked={specialData[`${code} - ${l} (${gender})`]}
                  disabled={disabled}
                />
                <label htmlFor={`${code} - ${l} (${gender})`}>
                  {l} ({gender})
                </label>
              </span>
            );
      }
    }

    return (
      <span className="special-select-section" key={nanoid()}>
        <strong>
          {department === "Level" ? department : `${department} - ${code}`}
        </strong>
        <span>{levels}</span>
      </span>
    );
  }

  function facultySection({ faculty, code }) {
    const levels = [];
    for (let h = 0; h < genders.length; h++) {
      const gender = genders[h];
      for (let l = 100; l <= 600; l += 100) {
        levels.push(
          <span key={nanoid()}>
            <input
              type="checkbox"
              name={`${code} - ${l} (${gender})`}
              id={`${code} - ${l} (${gender})`}
              onChange={handleChange}
              checked={specialData[`${code} - ${l} (${gender})`]}
              disabled={disabled}
            />
            <label htmlFor={`${code}-${l} (${gender})`}>
              {l} ({gender})
            </label>
          </span>
        );
      }
    }

    return (
      <span className="special-select-section" key={nanoid()}>
        <strong>{`Faculty of ${faculty} - ${code}`}</strong>
        <span>{levels}</span>
      </span>
    );
  }

  const specialElement = sortByProperty(departments, "programme")
    .filter(
      ({ programme, maxlevel, code }) =>
        programme.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        maxlevel.toString().indexOf(search) !== -1 ||
        code.toLowerCase().indexOf(search.toLowerCase()) !== -1
    )
    .map((dept) =>
      specialSection({
        department: dept.programme,
        level: dept.maxlevel,
        code: dept.code,
      })
    );

  const facultiesElement = sortByProperty(faculties, "faculty")
    .filter(
      ({ faculty, code }) =>
        faculty.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        code.toLowerCase().indexOf(search.toLowerCase()) !== -1
    )
    .map(({ faculty, code }) =>
      facultySection({
        faculty,
        code,
      })
    );

  const closeAction = () => {
    const allCheck = specialData["All"] ? { All: true } : specialData;
    const select = filterObject(allCheck, (value) => value);
    const selections = Object.keys(select);
    const set = selections.length !== 0;
    setFormData((old) => ({
      ...old,
      departments: set ? selections : "",
      display: set ? selections.slice(0, 3).join(", ") : "",
    }));
    close();
  };

  const selectedElements = Object.keys(
    filterObject(specialData, (value) => value)
  )
    .sort()
    .map((select) => (
      <span key={nanoid()}>
        <span>{select}</span>
        <LiaTimesCircle
          onClick={() => {
            setSpecialData((old) => ({ ...old, [select]: false }));
          }}
        />
      </span>
    ));

  return (
    <div className="special-select-wrapper departments-select">
      <div className="special-button">
        <span className="special-close" onClick={closeAction}>
          <FaCheckDouble />
        </span>
        <span className="special-clear" onClick={() => setSpecialData({})}>
          <FaTrash />
        </span>
      </div>
      <div
        className="form-group search-input"
        style={{ marginBottom: "5px", marginTop: 0 }}
      >
        <FaMagnifyingGlass />
        <input
          type="text"
          id="key"
          name="key"
          required
          value={search}
          onChange={handleSearch}
          maxLength={20}
          placeholder="Search"
        />
      </div>
      <div
        className="selection-box"
        style={
          selectedElements.length !== 0
            ? {}
            : { height: 0, padding: 0, opacity: 0, pointerEvents: "none" }
        }
      >
        {selectedElements}
      </div>
      <div className="special-select">
        {!search && (
          <>
            <span className="special-select-section">
              <strong>Special</strong>
              <span>
                <span>
                  <input
                    type="checkbox"
                    name="All"
                    id="All"
                    onChange={handleChange}
                    checked={specialData["All"]}
                  />
                  <label htmlFor="All">All</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name="All 2.0"
                    id="All 2.0"
                    onChange={handleChange}
                    checked={specialData["All 2.0"]}
                  />
                  <label htmlFor="All 2.0">All 2.0</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name="Freshers"
                    id="Freshers"
                    onChange={handleChange}
                    checked={specialData["Freshers"]}
                  />
                  <label htmlFor="Freshers">Freshers</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name="Direct Entry"
                    id="Direct Entry"
                    onChange={handleChange}
                    checked={specialData["Direct Entry"]}
                  />
                  <label htmlFor="Direct Entry">Direct Entry</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name="Finalists"
                    id="Finalists"
                    onChange={handleChange}
                    checked={specialData["Finalists"]}
                  />
                  <label htmlFor="Finalists">Finalists</label>
                </span>
                <span>
                  <label>
                    <FaCircleQuestion onClick={() => setInfo((old) => !old)} />
                  </label>
                </span>
              </span>
            </span>
            {info && (
              <span
                className="special-select-section"
                style={{ position: "relative" }}
              >
                <LiaTimesCircle
                  className="status-close"
                  style={{ color: "inherit", fontSize: "1.1em", right: "5px" }}
                  onClick={() => setInfo(false)}
                />
                <li>Freshers = 100 Level + Direct Entry</li>
                <li>
                  Finalist = Only Final Years .e.g. 400lvl Engineering !==
                  Finalist
                </li>
                <li>Others {">"} All</li>
                <li>All 2.0 {">"} Others</li>
                <li>Department Level {">"} Faculty Level</li>
                <li>Faculty Level {">"} Level</li>
              </span>
            )}
            <span className="special-select-section">
              <strong>Level</strong>
              <span>
                <span>
                  <input
                    type="checkbox"
                    name={`100 (M)`}
                    id={`100 (M)`}
                    onChange={handleChange}
                    checked={specialData[`100 (M)`]}
                    disabled={disabled}
                  />
                  <label htmlFor={`100 (M)`}>100 (M)</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name={`200 (M)`}
                    id={`200 (M)`}
                    onChange={handleChange}
                    checked={specialData[`200 (M)`]}
                    disabled={disabled}
                  />
                  <label htmlFor={`200 (M)`}>200 (M)</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name={`300 (M)`}
                    id={`300 (M)`}
                    onChange={handleChange}
                    checked={specialData[`300 (M)`]}
                    disabled={disabled}
                  />
                  <label htmlFor={`300 (M)`}>300 (M)</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name={`400 (M)`}
                    id={`400 (M)`}
                    onChange={handleChange}
                    checked={specialData[`400 (M)`]}
                    disabled={disabled}
                  />
                  <label htmlFor={`400 (M)`}>400 (M)</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name={`500 (M)`}
                    id={`500 (M)`}
                    onChange={handleChange}
                    checked={specialData[`500 (M)`]}
                    disabled={disabled}
                  />
                  <label htmlFor={`500 (M)`}>500 (M)</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name={`600 (M)`}
                    id={`600 (M)`}
                    onChange={handleChange}
                    checked={specialData[`600 (M)`]}
                    disabled={disabled}
                  />
                  <label htmlFor={`600 (M)`}>600 (M)</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name={`100 (F)`}
                    id={`100 (F)`}
                    onChange={handleChange}
                    checked={specialData[`100 (F)`]}
                    disabled={disabled}
                  />
                  <label htmlFor={`100 (F)`}>100 (F)</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name={`200 (F)`}
                    id={`200 (F)`}
                    onChange={handleChange}
                    checked={specialData[`200 (F)`]}
                    disabled={disabled}
                  />
                  <label htmlFor={`200 (F)`}>200 (F)</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name={`300 (F)`}
                    id={`300 (F)`}
                    onChange={handleChange}
                    checked={specialData[`300 (F)`]}
                    disabled={disabled}
                  />
                  <label htmlFor={`300 (F)`}>300 (F)</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name={`400 (F)`}
                    id={`400 (F)`}
                    onChange={handleChange}
                    checked={specialData[`400 (F)`]}
                    disabled={disabled}
                  />
                  <label htmlFor={`400 (F)`}>400 (F)</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name={`500 (F)`}
                    id={`500 (F)`}
                    onChange={handleChange}
                    checked={specialData[`500 (F)`]}
                    disabled={disabled}
                  />
                  <label htmlFor={`500 (F)`}>500 (F)</label>
                </span>
                <span>
                  <input
                    type="checkbox"
                    name={`600 (F)`}
                    id={`600 (F)`}
                    onChange={handleChange}
                    checked={specialData[`600 (F)`]}
                    disabled={disabled}
                  />
                  <label htmlFor={`600 (F)`}>600 (F)</label>
                </span>
              </span>
            </span>
          </>
        )}
        {facultiesElement}
        {specialElement}
      </div>
    </div>
  );
}
