import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa6";
import { addFaculty } from "../AppManager";
import { LiaTimesCircle } from "react-icons/lia";
import { useNavigate, useOutletContext } from "react-router-dom";

export default function AddFaculty() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    faculty: "",
    code: "",
  });
  const { departments } = useOutletContext();
  const navigate = useNavigate();

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);
      const exists = departments.filter(
        (dept) =>
          dept.code === formData.code || dept.faculty === formData.faculty
      );
      if (exists.length !== 0) {
        setTimeout(() => {
          setStatus({
            status: "Exists",
            type: "failed",
            message: "Faculty already Exists",
          });
          setLoading(false);
        }, 1000);
      } else {
        const res = await addFaculty(formData);
        if (res === "Success") {
          setTimeout(() => {
            setFormData({
              faculty: "",
              code: "",
            });
            setStatus({
              status: "success",
              type: "success",
              message: "Added Successfully. Redirecting...",
            });
            setTimeout(() => {
              navigate("/faculties", { replace: true });
            }, 2000);
          }, 2000);
        } else if (res === "Exists") {
          setStatus({
            status: "Exists",
            type: "failed",
            message: "Faculty already Exists",
          });
          setLoading(false);
        } else {
          setTimeout(() => {
            setStatus({
              status: "error",
              type: "failed",
              message: "Failed",
            });
            setLoading(false);
          }, 1000);
        }
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
    document.title = "Add Faculty";
  }, [status]);

  return (
    <>
      <div className="form-header overflow-y">
        <h1>Add Faculty</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="form-group">
            <label htmlFor="faculty">Faculty</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.faculty}
              id="faculty"
              name="faculty"
              required
              placeholder="Faculty"
              maxLength={100}
              minLength={3}
            />
          </div>
          <div className="form-group">
            <label htmlFor="code">Code</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.code}
              id="code"
              name="code"
              required
              placeholder="Code"
              maxLength={6}
              minLength={3}
              autoComplete="off"
              style={{ textTransform: "uppercase" }}
            />
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinner" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
