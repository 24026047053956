import React, { useEffect, useState } from "react";
import { FaChevronDown, FaSpinner } from "react-icons/fa6";
import { updateDepartment } from "../AppManager";
import { LiaTimesCircle } from "react-icons/lia";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { nanoid } from "nanoid";

export default function EditDepartment() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    programme: "",
    maxlevel: "",
    faculty: "",
    code: "",
  });
  const [department, setDepartment] = useState({
    programme: "",
    maxlevel: "",
    code: "",
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const { code } = useParams();
  const { faculties } = useOutletContext();

  useEffect(() => {
    if (
      state &&
      state.programme &&
      state.code &&
      state.faculty &&
      state.maxlevel &&
      code === state.code
    ) {
      const { programme, faculty, maxlevel, code } = state;
      setDepartment({ programme, faculty, maxlevel, code });
    } else {
      navigate("/departments");
    }
  }, [navigate, state, code]);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);
      const data = {
        ...formData,
        oldprogramme: department.programme,
        oldcode: department.code,
      };
      const res = await updateDepartment(data);
      if (res === "Updated") {
        setDepartment(formData);
        setTimeout(() => {
          setFormData({
            programme: "",
            code: "",
            faculty: "",
            maxlevel: "",
          });
          setStatus({
            status: "success",
            type: "success",
            message: "Updated Successfully. Redirecting...",
          });
          setTimeout(() => {
            navigate("/departments", { replace: true });
          }, 2000);
        }, 2000);
      } else if (res === "Exists") {
        setStatus({
          status: "Exists",
          type: "failed",
          message: "New Department already Exists",
        });
        setLoading(false);
      } else if (res === "Not Found") {
        setStatus({
          status: "not found",
          type: "failed",
          message: "Department not Found. Redirecting...",
        });
        setTimeout(() => {
          navigate("/departments", { replace: true });
        }, 2000);
      } else {
        setTimeout(() => {
          setStatus({
            status: "error",
            type: "failed",
            message: "Update Failed",
          });
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Update Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
    document.title = "Edit Department";
  }, [status]);

  const facultyElement = faculties.map((fac) => (
    <option value={fac.faculty} key={nanoid()}>
      {fac.faculty}
    </option>
  ));

  return (
    <>
      <div className="form-header overflow-y">
        <h1>Edit Department</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="edit-group">
            <div>
              <h5 className="text-center fw-bolder mb-0">Current Details</h5>
            </div>
            <div className="edit-details" style={{ justifyContent: "initial" }}>
              <div className="edit-details-text">
                <p style={{ marginBottom: "5px" }}>
                  <strong>Programme: </strong>
                  {department.programme}
                </p>
                <p style={{ marginBottom: "5px" }}>
                  <strong>Code: </strong>
                  {department.code}
                </p>
                <p style={{ marginBottom: "5px" }}>
                  <strong>Faculty: </strong>
                  {department.faculty}
                </p>
                <p style={{ marginBottom: "5px" }}>
                  <strong>Max Level: </strong>
                  {department.maxlevel} Level
                </p>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="programme">Programme</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.programme}
              id="programme"
              name="programme"
              required
              placeholder="Programme"
              maxLength={30}
              minLength={3}
            />
          </div>
          <div className="form-group">
            <label htmlFor="code">Code</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.code}
              id="code"
              name="code"
              required
              placeholder="Code"
              pattern="^[A-Za-z]{3}$"
              maxLength={3}
              minLength={3}
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <label htmlFor="faculty">Faculty</label>
            <select
              disabled={loading}
              onChange={handleChange}
              value={formData.faculty}
              name="faculty"
              id="faculty"
              required
            >
              <option disabled value="">
                Choose one
              </option>
              {facultyElement}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="maxlevel">Max Level</label>
            <input
              type="number"
              disabled={loading}
              onChange={handleChange}
              value={formData.maxlevel}
              name="maxlevel"
              id="maxlevel"
              placeholder="Max Level"
              required
              maxLength={3}
              minLength={3}
              max={900}
              min={300}
            />
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinner" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
