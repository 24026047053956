import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa6";
import { addHostel } from "../AppManager";
import { LiaTimesCircle } from "react-icons/lia";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

export default function AddHostel() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    hostel: "",
  });
  const { hostels } = useOutletContext();
  const { gender } = useParams();
  const navigate = useNavigate();

  let hosts = hostels
    .filter((hos) => hos.gender === gender)
    .map(({ hostel }) => hostel);
  hosts = Array.from(new Set(hosts));

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);
      const data = { ...formData, gender };
      const exists = hosts.filter(
        (host) => host.toLowerCase() === formData.hostel.toLowerCase().trim()
      );
      if (exists.length !== 0) {
        setTimeout(() => {
          setStatus({
            status: "Exists",
            type: "failed",
            message: "Hostel already Exists",
          });
          setLoading(false);
        }, 1000);
      } else {
        const res = await addHostel(data);
        if (res === "Success") {
          setTimeout(() => {
            setFormData({
              hostel: "",
            });
            setStatus({
              status: "success",
              type: "success",
              message: "Added Successfully. Redirecting...",
            });
            setTimeout(() => {
              navigate(`/hostels/${gender}`);
            }, 1000);
          }, 2000);
        } else if (res === "Exists") {
          setStatus({
            status: "Exists",
            type: "failed",
            message: "Hostel already Exists",
          });
          setLoading(false);
        } else {
          setTimeout(() => {
            setStatus({
              status: "error",
              type: "failed",
              message: "Failed",
            });
            setLoading(false);
          }, 1000);
        }
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  document.title = "Add Hostel";

  return (
    <>
      <div className="form-header overflow-y">
        <h1>Add Hostel</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="form-group">
            <label htmlFor="hostel">Hostel</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.hostel}
              id="hostel"
              name="hostel"
              pattern="[A-Za-z0-9 ]+"
              required
              placeholder="Hostel"
              maxLength={50}
              minLength={3}
            />
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinner" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
