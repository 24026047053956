import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import {
  createUniqueArray,
  deepEqual,
  extractIdnum,
  getDepartmentCode,
  getFacultyCode,
  isRangeSubset,
  studentsUpload,
} from "../AppManager";
import { FaCheckDouble, FaCopy, FaEye, FaSpinner } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import { useOutletContext } from "react-router-dom";

export default function Upload() {
  const [students, setStudents] = useState(0);
  const [depts, setDepts] = useState([]);
  const [facts, setFacts] = useState([]);
  const [hsts, setHsts] = useState([]);
  const [gnds, setGnds] = useState([]);
  const [lvls, setLvls] = useState([]);
  const [matric, setMatric] = useState([]);
  const [matricDouble, setMatricDouble] = useState([]);
  const [excluded, setExcluded] = useState([]);
  const [sheetsError, setSheetsError] = useState(false);
  const [header, setHeader] = useState(false);
  const [current, setCurrent] = useState("");
  const [completed, setCompleted] = useState({});
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [file, setFile] = useState([]);
  const fileInputRef = useRef(null);
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [errorText, setErrorText] = useState("");
  const [errorFile, setErrorFile] = useState("");
  const { departments, faculties, hostels } = useOutletContext();

  const headers = [
    "Surname",
    "First Name",
    "Matric",
    "Gender",
    "Faculty",
    "Department",
    "Level",
    "Hostel",
    "Block",
    "Room",
  ];

  function resetLogs() {
    setCurrent("");
    setHeader(false);
    setSheetsError(false);
    setStudents(0);
    setMatric([]);
    setMatricDouble([]);
    setDepts([]);
    setFacts([]);
    setHsts([]);
    setLvls([]);
    setGnds([]);
    setExcluded([]);
    setCompleted([]);
    fileInputRef.current.value = null;
    setStatus({
      status: "",
      type: "",
      message: "",
    });
  }

  const handleCopy = () => {
    const textToCopy = document.querySelector(".student-upload-text").innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {});
  };

  function handleChange(event) {
    const file = event.target.files[0];
    setFile(file);

    const fileCheck = file && checkFile(file, 1024 * 5);
    if (fileCheck === "size") {
      setErrorFile("File is too Large, Maximum of 5MB.");
    } else {
      setErrorFile("");
    }
    if (!file || fileCheck !== true) {
      resetLogs();
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheets = workbook.SheetNames;

      if (
        sheets.length !== 2 ||
        !sheets.includes("Male") ||
        !sheets.includes("Female")
      ) {
        setSheetsError(true);
        return;
      } else {
        setSheetsError(false);

        for (let k = 0; k < sheets.length; k++) {
          const sheet = sheets[k];
          setCurrent(sheet);
          const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);

          const headerStud = Object.keys(jsonData[0]);
          if (!deepEqual(headerStud.sort(), headers.sort())) {
            setHeader(true);
            break;
          }
          setHeader(false);

          let students = 0;
          let excluded = [];
          let matric = [];
          let dept = [];
          let fact = [];
          let hst = [];
          let lvl = [];
          let gnd = [];
          let idNumStore = {};
          let idNumDuplicate = {};
          for (let k = 0; k < jsonData.length; k++) {
            let student = jsonData[k];
            const row = student["__rowNum__"] + 1;
            if (!student.hasOwnProperty("Matric")) {
              matric.push(row);
            } else {
              const key = extractIdnum(student["Matric"]);
              if (!key) {
                continue;
              }
              let exclude = false;

              // Check for Duplicate Matric Numbers
              if (idNumStore.hasOwnProperty(key)) {
                exclude = false;
                excluded.push(idNumStore[key], row);
                if (idNumDuplicate.hasOwnProperty(key)) {
                  idNumDuplicate[key] = `${idNumDuplicate[key]} & ${row}`;
                } else {
                  idNumDuplicate[key] = `${idNumStore[key]} & ${row}`;
                }
              } else {
                idNumStore[key] = row;
              }

              for (let p = 0; p < headers.length; p++) {
                const header = headers[p];
                if (!student.hasOwnProperty(header)) {
                  excluded.push(row);
                  exclude = true;
                  break;
                }
              }
              if (!getDepartmentCode(student.Department, departments)) {
                exclude = false;
                excluded.push(row);
                dept.push(row);
              }
              if (!getFacultyCode(student.Faculty, faculties)) {
                exclude = false;
                excluded.push(row);
                fact.push(row);
              }
              if (student.Gender.trim() !== (sheet === "Male" ? "M" : "F")) {
                exclude = false;
                excluded.push(row);
                gnd.push(row);
              }
              if (
                isNaN(parseInt(student.Level)) ||
                parseInt(student.Level) === 0 ||
                parseInt(student.Level) % 100 !== 0 ||
                parseInt(student.Level) > 700
              ) {
                exclude = false;
                excluded.push(row);
                lvl.push(row);
              }
              if (
                !hostelExists(
                  hostels,
                  sheet === "Male" ? "M" : "F",
                  student.Hostel,
                  student.Block,
                  student.Room
                )
              ) {
                exclude = false;
                excluded.push(row);
                hst.push(row);
              }
              if (!exclude) {
                student = { ...student, Idnum: key };
                const stud = {};
                const studentKeys = Object.keys(student);
                for (let h = 0; h < studentKeys.length; h++) {
                  const key = studentKeys[h];
                  stud[key.toLowerCase()] = student[key];
                }
                students++;
              }
            }
          }
          if (matric.length === 0 && excluded.length === 0) {
            setStudents(students);
          }
          setExcluded(createUniqueArray(excluded));
          setMatric(createUniqueArray(matric));
          setDepts(createUniqueArray(dept));
          setFacts(createUniqueArray(fact));
          setHsts(createUniqueArray(hst));
          setGnds(createUniqueArray(gnd));
          setLvls(createUniqueArray(lvl));
          setMatricDouble(createUniqueArray(Object.values(idNumDuplicate)));

          const invalid = matric.length + excluded.length;
          if (!(students !== 0 && invalid === 0 && !sheetsError && !header)) {
            break;
          }

          setCompleted((old) => ({ ...old, [sheet]: students }));
        }
      }
    };

    reader.readAsArrayBuffer(file);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      if (students !== 0) {
        setLoading(true);
        const send = await studentsUpload({
          file,
        });

        if (send === "Success") {
          resetLogs();
          setStatus({
            status: "success",
            type: "success",
            message: "Upload Successful",
          });
        } else if (send === "Corrupt Data") {
          setStatus({
            status: "data",
            type: "failed",
            message: "Corrupt Data",
          });
        } else if (send === "Exists") {
          setStatus({
            status: "exists",
            type: "failed",
            message: "Month Record Exists",
          });
        } else {
          setStatus({
            status: "error",
            type: "failed",
            message: "Upload Failed",
          });
        }
        setLoading(false);
      }
    } catch (error) {
      setStatus({
        status: "error",
        type: "failed",
        message: "Upload Failed",
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    const textContainer = document.getElementsByClassName("truncate-text");
    if (textContainer.length === 0) {
      setHidden(false);
    }
    for (let k = 0; k < textContainer.length; k++) {
      const container = textContainer[k];
      setHidden(isEllipsisActive(container));

      container.addEventListener("resize", function () {
        setHidden(isEllipsisActive(container));
      });
    }

    function isEllipsisActive(element) {
      return (
        element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth
      );
    }

    return () => {
      for (let k = 0; k < textContainer.length; k++) {
        const container = textContainer[k];
        container.removeEventListener("resize", function () {
          setHidden(isEllipsisActive(container));
        });
      }
    };
  }, [students, matric, excluded]);

  document.title = "Students Upload";

  const valid = students;
  const invalid = matric.length + excluded.length;

  return (
    <>
      {errorText && (
        <div className="student-upload-viewer">
          <LiaTimesCircle
            onClick={() => {
              setErrorText("");
            }}
          />
          <div>
            {Object.keys(completed).map((complete) => (
              <>
                <strong style={{ color: "green" }}>
                  Analyzed {complete} Students Sheet Successfully!!!
                </strong>
                <br />
              </>
            ))}
            {sheetsError && (
              <>
                <p>Excel File Sheets do not meet the requirements</p>
                <p>
                  Rename <strong>Sheet1</strong> and <strong>Sheet2</strong> to{" "}
                  <strong>Male</strong> and <strong>Female</strong> or vice
                  versa to fix this problem
                </p>
              </>
            )}
            {Object.keys(completed).length !== 2 && current && (
              <>
                <strong>Analyzing {current} Students Sheet</strong>
                <br />
                <br />
              </>
            )}
            {header && (
              <>
                <p>Columns Header do not meet the requirements</p>
                <p>
                  <strong>{headers.join(", ")}</strong>
                  <br />
                  <br />
                  Ensure all the headers above are <strong>
                    present
                  </strong> and <strong>named</strong> as above in a case
                  sensitive manner
                </p>
                <p>
                  If this error persists after ensuring the above, ensure that
                  no column is <strong>empty</strong> in <strong>Row 2</strong>
                </p>
              </>
            )}
            {matric.length !== 0 && (
              <p>
                Missing <strong>Matric Number</strong> at Row
                {matric.length > 1 && "s"}: <span>{matric.join(", ")}</span>
              </p>
            )}
            {matricDouble.length !== 0 && (
              <p>
                Duplicate <strong>Matric Number</strong> at Row
                {matricDouble.length > 1 && "s"}:{" "}
                <span>{matricDouble.join(", ")}</span>
              </p>
            )}
            {gnds.length !== 0 && (
              <p>
                Invalid <strong>Gender</strong> at Row
                {gnds.length > 1 && "s"}: <span>{gnds.join(", ")}</span>
                <br />
                Expected Value is{" "}
                <strong>{current === "Male" ? "M" : "F"}</strong>
              </p>
            )}
            {lvls.length !== 0 && (
              <p>
                Invalid <strong>Level</strong> at Row
                {lvls.length > 1 && "s"}: <span>{lvls.join(", ")}</span>
                <br />
                Expected Values are <strong>100, 200, 300, ...</strong>
              </p>
            )}
            {depts.length !== 0 && (
              <p>
                Unregistered / Invalid <strong>Department</strong> at Row
                {depts.length > 1 && "s"}: <span>{depts.join(", ")}</span>
              </p>
            )}
            {facts.length !== 0 && (
              <p>
                Unregistered / Invalid <strong>Faculty</strong> at Row
                {facts.length > 1 && "s"}: <span>{facts.join(", ")}</span>
              </p>
            )}
            {hsts.length !== 0 && (
              <p>
                Unregistered / Invalid <strong>Hostel / Block / Room</strong> at
                Row
                {hsts.length > 1 && "s"}: <span>{hsts.join(", ")}</span>
              </p>
            )}
            {excluded.length !== 0 && (
              <p>
                Missing / Invalid <strong>Data</strong> at Row
                {excluded.length > 1 && "s"}: <span>{excluded.join(", ")}</span>
              </p>
            )}
            {invalid !== 0 && (
              <>
                <p>
                  <strong>
                    {invalid} Student{invalid > 1 && "s"}
                  </strong>{" "}
                  Information{invalid > 1 && "s"} {invalid > 1 ? "are" : "is"}{" "}
                  Invalid.
                </p>
                <p>Fix these issues to proceed with upload.</p>
              </>
            )}
            {Object.keys(completed).length === 2 &&
              valid !== 0 &&
              invalid === 0 && (
                <>
                  <br />
                  <p>
                    <strong>
                      {completed.Male + completed.Female} Student
                      {completed.Male + completed.Female > 1 && "s"}
                    </strong>{" "}
                    Information{completed.Male + completed.Female > 1 && "s"}{" "}
                    extracted successfully,
                  </p>
                  <p>Proceed with Upload?</p>
                </>
              )}
            <p>{errorFile}</p>
            {valid === 0 && invalid === 0 && <p>Choose A Valid Excel File.</p>}
          </div>
        </div>
      )}
      <div className="form-header confirm-form">
        <h1>Students Upload</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div>
            <div className="form-group">
              <label htmlFor="file">Excel File</label>
              <input
                type="file"
                accept=".xls,.xlsx"
                onChange={handleChange}
                id="file"
                name="file"
                ref={fileInputRef}
                disabled={loading}
                required
              />
            </div>
            <div className="student-upload-text">
              <span
                className={`student-upload-copy student-upload-view ${
                  hidden && "flicker"
                }`}
                title="View"
              >
                <FaEye
                  onClick={() => {
                    setErrorText("show");
                  }}
                />
              </span>
              <span
                className={`student-upload-copy ${hidden && "flicker"}`}
                title="Copy"
              >
                {!copied && <FaCopy onClick={handleCopy} />}
                {copied && (
                  <FaCheckDouble
                    style={{ fontSize: "0.9em", cursor: "default" }}
                  />
                )}
              </span>
              {Object.keys(completed).map((complete) => (
                <>
                  <strong style={{ color: "green" }}>
                    Analyzed {complete} Students Sheet Successfully!!!
                  </strong>
                  <br />
                </>
              ))}
              {sheetsError && (
                <>
                  <p>Excel File Sheets do not meet the requirements</p>
                  <p>
                    Rename <strong>Sheet1</strong> and <strong>Sheet2</strong>{" "}
                    to <strong>Male</strong> and <strong>Female</strong> or vice
                    versa to fix this problem
                  </p>
                </>
              )}
              {Object.keys(completed).length !== 2 && current && (
                <>
                  <strong>Analyzing {current} Students Sheet</strong>
                  <br />
                  <br />
                </>
              )}
              {header && (
                <>
                  <p>Columns Header do not meet the requirements</p>
                  <p>
                    <strong>{headers.join(", ")}</strong>
                    <br />
                    <br />
                    Ensure all the headers above are <strong>
                      present
                    </strong>{" "}
                    and <strong>named</strong> as above in a{" "}
                    <strong>case sensitive</strong> manner
                  </p>
                  <p>
                    If this error persists after ensuring the above, ensure that
                    no column is <strong>empty</strong> in{" "}
                    <strong>Row 2</strong>
                  </p>
                </>
              )}
              {matric.length !== 0 && (
                <p>
                  Missing <strong>Matric Number</strong> at Row
                  {matric.length > 1 && "s"}:{" "}
                  <span className="truncate-text">{matric.join(", ")}</span>
                </p>
              )}
              {matricDouble.length !== 0 && (
                <p>
                  Duplicate <strong>Matric Number</strong> at Row
                  {matricDouble.length > 1 && "s"}:{" "}
                  <span className="truncate-text">
                    {matricDouble.join(", ")}
                  </span>
                </p>
              )}
              {gnds.length !== 0 && (
                <p>
                  Invalid <strong>Gender</strong> at Row
                  {gnds.length > 1 && "s"}:{" "}
                  <span className="truncate-text">{gnds.join(", ")}</span>
                  <br />
                  Expected Value is{" "}
                  <strong>{current === "Male" ? "M" : "F"}</strong>
                </p>
              )}
              {lvls.length !== 0 && (
                <p>
                  Invalid <strong>Level</strong> at Row
                  {lvls.length > 1 && "s"}:{" "}
                  <span className="truncate-text">{lvls.join(", ")}</span>
                  <br />
                  Expected Values are <strong>100, 200, 300, ...</strong>
                </p>
              )}
              {depts.length !== 0 && (
                <p>
                  Unregistered / Invalid <strong>Department</strong> at Row
                  {depts.length > 1 && "s"}:{" "}
                  <span className="truncate-text">{depts.join(", ")}</span>
                </p>
              )}
              {facts.length !== 0 && (
                <p>
                  Unregistered / Invalid <strong>Faculty</strong> at Row
                  {facts.length > 1 && "s"}:{" "}
                  <span className="truncate-text">{facts.join(", ")}</span>
                </p>
              )}
              {hsts.length !== 0 && (
                <p>
                  Unregistered / Invalid <strong>Hostel / Block / Room</strong>{" "}
                  at Row
                  {hsts.length > 1 && "s"}:{" "}
                  <span className="truncate-text">{hsts.join(", ")}</span>
                </p>
              )}
              {excluded.length !== 0 && (
                <p>
                  Missing / Invalid <strong>Data</strong> at Row
                  {excluded.length > 1 && "s"}:{" "}
                  <span className="truncate-text">{excluded.join(", ")}</span>
                </p>
              )}
              {invalid !== 0 && (
                <>
                  <p>
                    <strong>
                      {invalid} Student{invalid > 1 && "s"}
                    </strong>{" "}
                    Information{invalid > 1 && "s"} {invalid > 1 ? "are" : "is"}{" "}
                    Invalid.
                  </p>
                  <p>Fix these issues to proceed with upload.</p>
                </>
              )}
              {Object.keys(completed).length === 2 &&
                valid !== 0 &&
                invalid === 0 && (
                  <>
                    <br />
                    <p>
                      <strong>
                        {completed.Male + completed.Female} Student
                        {completed.Male + completed.Female > 1 && "s"}
                      </strong>{" "}
                      Information{completed.Male + completed.Female > 1 && "s"}{" "}
                      extracted successfully,
                    </p>
                    <p>Proceed with Upload?</p>
                  </>
                )}
              <p>{errorFile}</p>
              {valid === 0 && invalid === 0 && (
                <>
                  <p>Choose A Valid Excel File.</p>
                  <a href="/A Valid Excel File.pdf" target="_blank">
                    What is A Valid Excel File?
                  </a>
                </>
              )}
              {hidden && (
                <p style={{ color: "darkred" }}>
                  <i>
                    Click the <strong>Copy</strong> or <strong>View</strong>{" "}
                    icon to access full <strong>Error Message</strong>.
                  </i>
                </p>
              )}
            </div>
          </div>
          {Object.keys(completed).length === 2 &&
            students !== 0 &&
            invalid === 0 && (
              <div className="form-group">
                <button type="submit" disabled={loading}>
                  {loading ? <FaSpinner className="spinner" /> : "Upload"}
                </button>
              </div>
            )}
        </form>
      </div>
    </>
  );
}

function checkExtension(string) {
  const extensions = ["xls", "xlsx"];
  const ext = string.slice(string.lastIndexOf(".") + 1).toLowerCase();
  if (extensions.includes(ext)) {
    return true;
  }
  return false;
}

function checkFile(file, maxSize) {
  const maximum = 1024 * maxSize;
  if (file.size <= maximum && checkExtension(file.name)) {
    return true;
  } else {
    if (file.size > maximum) {
      return "size";
    } else if (!checkExtension(file.name)) {
      return "format";
    }
  }
}

function hostelExists(hostels, gender, hostel, block, room) {
  const hexists = hostels.filter(
    (hos) =>
      hos.hostel.trim().toLowerCase() === hostel.trim().toLowerCase() &&
      hos.gender.trim().toLowerCase() === gender.trim().toLowerCase()
  );

  if (hexists.length !== 0) {
    const bexists = hexists.filter(
      (hos) =>
        hos.block.trim().toLowerCase().split(" ")[0] ===
        block.trim().toLowerCase().split(" ")[0]
    );
    if (bexists.length !== 0) {
      const brexists = bexists.filter((blo) =>
        isRangeSubset(block.trim().split(" ").pop(), blo.brange)
      );
      if (brexists.length !== 0) {
        const rexists = hexists.filter(
          (hos) =>
            hos.room.trim().toLowerCase().split(" ")[0] ===
            room.trim().toLowerCase().split(" ")[0]
        );

        if (rexists.length !== 0) {
          const rrexists = rexists.filter((blo) =>
            isRangeSubset(room.trim().split(" ").pop(), blo.rrange)
          );
          return rrexists.length !== 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}
