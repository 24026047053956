import React, { useEffect, useState } from "react";
import { LiaTimesCircle } from "react-icons/lia";
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import { showSwal, stripSpace } from "../AppManager";
import { FaSpinner } from "react-icons/fa6";
import { axios } from "../Auth";

export default function DeletePastEvent() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({ key: "", password: "" });
  const [keyword, setKeyword] = useState("loading...");
  const navigate = useNavigate();
  const { centre } = useParams();

  useEffect(() => {
    const trimname = stripSpace(centre);
    setKeyword(
      trimname.length >= 10
        ? trimname.slice(0, 10)
        : trimname.padEnd("10", nanoid(10 - trimname.length))
    );
    document.title = "Delete Event";
  }, [centre]);

  function handleChange(e) {
    const { name, value } = e.target;
    name === "key" && setDisabled(value !== keyword);
    setFormData((oldData) => ({
      ...oldData,
      [name]: name === "key" ? value.slice(0, 10) : value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);
      if (formData.key === keyword) {
        let formdata = new FormData();
        formdata.append("centre", centre);
        formdata.append("password", formData.password);
        formdata.append("delete_past_event", "fm93n43usj");
        const url = "php/delete.php";
        const send = await axios.post(url, formdata);
        const res = send.data;

        if (res === "Success") {
          navigate("/time/past/events", { replace: true });
          showSwal("success", "Event Deleted");
        } else if (res === "Password") {
          setStatus({
            status: "error",
            type: "failed",
            message: "Incorrect Password",
          });
          setLoading(false);
        } else if (res === "Not Found") {
          showSwal("error", "Event not Found");
          navigate("/time/past/events", { replace: true });
        } else {
          setTimeout(() => {
            setStatus({
              status: "error",
              type: "failed",
              message: "Delete Failed",
            });
            setLoading(false);
          }, 1000);
        }
      } else {
        setTimeout(() => {
          setStatus({
            status: "error",
            type: "error",
            message: "Incorrect Key",
          });
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Delete Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  return (
    <>
      <div className="form-header">
        <h1>Delete Event</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="delete-message">
            <p>
              Type <strong>{keyword}</strong> below to confirm delete
            </p>
            <i>
              <strong>Note:</strong> This action is irreversible and cannot be
              undone.
            </i>
          </div>
          <div className="form-group">
            <label htmlFor="key">Key</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.key}
              id="key"
              name="key"
              required
              placeholder={keyword}
              minLength={10}
              maxLength={10}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              disabled={loading}
              type="password"
              onChange={handleChange}
              value={formData.password}
              id="password"
              name="password"
              required
              placeholder={"Password"}
            />
          </div>
          <div className="form-group">
            <button disabled={disabled || loading || keyword === "loading..."}>
              {loading ? <FaSpinner className="spinner" /> : "Confirm"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
