import React, { useState } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import { FaCaretRight, FaMagnifyingGlass, FaPlus } from "react-icons/fa6";

export default function Hostels() {
  const { hostels } = useOutletContext();
  const { gender } = useParams();
  const [search, setSearch] = useState("");

  function handleSearch(e) {
    function cleanString(input) {
      // Regular expression to match only letters (both cases), numbers, and spaces
      const regex = /[^a-zA-Z0-9 ]/g;
      // Replace any character that is not a letter, number, or space with an empty string
      return input.replace(regex, "");
    }

    setSearch(cleanString(e.target.value));
  }

  let hosts = hostels
    .filter((hos) => hos.gender === gender)
    .map(({ hostel }) => hostel);
  hosts = Array.from(new Set(hosts));

  const hostelsElement = hosts
    .filter((hos) => hos.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    .map((hostel) => (
      <div key={nanoid()}>
        <span>{hostel}</span>
        <Link to={`${hostel}`}>
          <FaCaretRight />
        </Link>
      </div>
    ));

  document.title = "Hostels";

  return (
    <>
      <div className="form-header" style={{ marginBottom: "0" }}>
        <h1>
          {gender === "M" && "Male "}
          {gender === "F" && "Female "}Hostels
        </h1>
      </div>
      {hosts.length > 5 && (
        <div
          className="form-group search-input"
          style={{ marginBottom: "5px", marginTop: 0 }}
        >
          <FaMagnifyingGlass />
          <input
            type="text"
            id="key"
            name="key"
            required
            value={search}
            onChange={handleSearch}
            maxLength={20}
            placeholder="Search"
          />
        </div>
      )}
      <div className="departments-wrapper">
        <div>
          <span>Add Hostel</span>
          <Link to={"add"}>
            <FaPlus />
          </Link>
        </div>
        {hostelsElement}
      </div>
    </>
  );
}
