import { useEffect } from "react";
import { deepEqual } from "../AppManager";

export default function useRefetchArray(fetcherFunction, setData) {
  useEffect(() => {
    let timeout;
    async function fetcher(previous = []) {
      const data = await fetcherFunction();
      const result = [];
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const dat = data[key];
          result.push(dat);
        }
      }

      !deepEqual(result, previous) && setData(result);
      timeout = setTimeout(() => {
        fetcher(result);
      }, 5000);
    }
    fetcher();
    return () => {
      clearTimeout(timeout);
    };
  }, [fetcherFunction, setData]);
}
