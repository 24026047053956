import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa6";
import { updateFaculty } from "../AppManager";
import { LiaTimesCircle } from "react-icons/lia";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function EditFaculty() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    faculty: "",
    code: "",
  });
  const [faculty, setFaculty] = useState({
    faculty: "",
    code: "",
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const { code } = useParams();

  useEffect(() => {
    if (state && state.faculty && state.code && code === state.code) {
      const { faculty, code } = state;
      setFaculty({ faculty, code });
    } else {
      navigate("/faculties");
    }
  }, [navigate, state, code]);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);
      const data = {
        ...formData,
        oldfaculty: faculty.faculty,
        oldcode: faculty.code,
      };
      const res = await updateFaculty(data);
      if (res === "Updated") {
        setFaculty(formData);
        setTimeout(() => {
          setFormData({
            faculty: "",
            code: "",
          });
          setStatus({
            status: "success",
            type: "success",
            message: "Updated Successfully. Redirecting...",
          });
          setTimeout(() => {
            navigate("/faculties", { replace: true });
          }, 2000);
        }, 2000);
      } else if (res === "Exists") {
        setStatus({
          status: "Exists",
          type: "failed",
          message: "New Faculty already Exists",
        });
        setLoading(false);
      } else if (res === "Not Found") {
        setStatus({
          status: "not found",
          type: "failed",
          message: "Faculty not Found. Redirecting...",
        });
        setTimeout(() => {
          navigate("/faculties", { replace: true });
        }, 2000);
      } else {
        setTimeout(() => {
          setStatus({
            status: "error",
            type: "failed",
            message: "Update Failed",
          });
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Update Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
    document.title = "Edit Faculty";
  }, [status]);

  return (
    <>
      <div className="form-header overflow-y">
        <h1>Edit Faculty</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="edit-group">
            <div>
              <h5 className="text-center fw-bolder mb-0">Current Details</h5>
            </div>
            <div className="edit-details" style={{ justifyContent: "initial" }}>
              <div className="edit-details-text">
                <p style={{ marginBottom: "5px" }}>
                  <strong>Faculty: </strong>
                  {faculty.faculty}
                </p>
                <p style={{ marginBottom: "5px" }}>
                  <strong>Code: </strong>
                  {faculty.code}
                </p>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="faculty">Faculty</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.faculty}
              id="faculty"
              name="faculty"
              required
              placeholder="Faculty"
              maxLength={30}
              minLength={3}
            />
          </div>
          <div className="form-group">
            <label htmlFor="code">Code</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.code}
              id="code"
              name="code"
              required
              placeholder="Code"
              maxLength={6}
              minLength={3}
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinner" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
