import axiosMain from "axios";

export const origin = window.location.origin;

const local = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
export const baseURL = local
  ? `${process.env.REACT_APP_LOCAL}/public/`
  : origin + "/";
export const downloadBaseURL = local
  ? `${process.env.REACT_APP_LOCAL}/files/`
  : origin + "/files/";

export const generalURL = local
  ? process.env.REACT_APP_GENERAL_URL
  : process.env.REACT_APP_GENERAL;

export const axios = axiosMain.create({
  baseURL,
});

axios.interceptors.request.use(
  (config) => {
    const tokenData = getCentreToken();
    const { centre, token } = tokenData;

    if (centre && token) {
      if (["post", "put", "patch"].includes(config.method)) {
        if (config.data instanceof FormData) {
          config.data.append("auth_centre", centre);
          config.data.append("auth_token", token);
        } else {
          config.data = {
            auth_centre: centre,
            auth_token: token,
            ...config.data,
          };
        }
      }
    }

    config.mode = config.mode || "cors";

    if (!(config.data instanceof FormData)) {
      config.headers = {
        ...config.headers,
        "Content-Type": config.headers?.["Content-Type"] || "application/json",
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function setCookie(cname, cvalue, exdays = 1 / 3) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + "; path=/";
}

export function removeCookie(cname) {
  document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getCentreToken() {
  try {
    const toke = getCookie("om77777 n85fd");

    if (!toke) {
      return { centre: null, token: null };
    }

    const { centre, token } = JSON.parse(decodeString(toke));
    return { centre, token };
  } catch (error) {
    return { centre: null, token: null };
  }
}

export async function authentication() {
  try {
    const toke = getCentreToken();
    if (!toke) {
      return false;
    }
    const data = {
      authentication: "fmjkertkj",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata);
    const res = send.data;

    if (typeof res === "object" && res.status === "Success") {
      const { centre, token } = res;
      const data = encodeString(JSON.stringify({ centre, token }));
      setCookie("om77777 n85fd", data);
      return true;
    }
    return false;
  } catch (error) {
    return false;
    // return error;
  }
}

export const clearUser = () => {
  try {
    removeCookie("om77777 n85fd");
    return true;
  } catch (error) {
    removeCookie("om77777 n85fd");
    return false;
  }
};

export async function login(formData) {
  try {
    const data = { ...formData, login: "lmhiturnd" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata);
    const res = send.data;
    if (typeof res === "object" && res.status === "Success") {
      const { centre, token } = res;
      const data = encodeString(JSON.stringify({ centre, token }));
      setCookie("om77777 n85fd", data);
      return "Success";
    } else {
      return res;
    }
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function forgottenPassword(formData) {
  try {
    const data = { ...formData, forgot_password: "ijnk jnij iuh8979" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata);
    const res = send.data;

    return res;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function passwordReset(formData) {
  try {
    const data = {
      ...formData,
      password_reset: "nwfejkr kkwejnr2",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return ["Failed"];
    // return error;
  }
}

export async function changePassword(formData) {
  try {
    const data = {
      ...formData,
      change_password: "mjeisn3u54t ",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function randomPassword(formData) {
  try {
    const data = {
      ...formData,
      random_password: "mou98b5cctd",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function tokenRequest(formData) {
  try {
    const data = {
      ...formData,
      token_request: "mkfni3u4nnkd",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function tokenRequestRandom(formData) {
  try {
    const data = {
      ...formData,
      random_token_request: "nsiuNJk",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function verifyToken(token) {
  try {
    const data = {
      token,
      verify_token: "mwkrkjesk",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata);
    if (send.data === "Success") {
      return true;
    }
    return false;
  } catch (error) {
    return false;
    // return error;
  }
}

export function encodeString(string) {
  const key = process.env.REACT_APP_SUPER_KEY;
  let encoded = "";
  for (let i = 0; i < string.length; i++) {
    // XOR each character with the key character, cycling through the key
    let charCode = string.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    encoded += String.fromCharCode(charCode);
  }
  return btoa(encoded); // Convert to base64 to make it printable
}

export function decodeString(encodedString) {
  const key = process.env.REACT_APP_SUPER_KEY;
  let decodedBase64 = atob(encodedString); // Convert from base64
  let decoded = "";
  for (let i = 0; i < decodedBase64.length; i++) {
    // XOR each character with the key character, cycling through the key
    let charCode = decodedBase64.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    decoded += String.fromCharCode(charCode);
  }
  return decoded;
}

export function passwordGenerator(inputString) {
  if (!inputString || typeof inputString !== "string" || inputString.length < 3)
    return "";

  // If string contains more than 1 word, select any random word
  const split = inputString.split(" ");
  const randomIndex = Math.floor(Math.random() * split.length);
  let string = split[randomIndex];

  string = string.toLowerCase();
  let length = string.length;

  // Pad string to length of 5 if less than 5
  if (length < 5) {
    const mix = string
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
    string = string.padEnd(5, mix);
  }

  // Capitalize 1 / 2 / 3 random characters
  const capitalizeCount = Math.floor(Math.random() * 3) + 1;
  for (let i = 0; i < capitalizeCount; i++) {
    const index = Math.floor(Math.random() * string.length);
    string =
      string.substring(0, index) +
      string[index].toUpperCase() +
      string.substring(index + 1);
  }

  // Add random integers
  const intCount = Math.floor(Math.random() * 4) + 1;
  let randomInt = Math.floor(Math.random() * 100).toString();
  if (intCount === 1) {
    string += randomInt;
  } else if (intCount === 2) {
    string = randomInt + string;
  } else if (intCount === 3) {
    const half = Math.floor(string.length / 2);
    string = string.substring(0, half) + randomInt + string.substring(half);
  } else {
    string += randomInt;
    randomInt = Math.floor(Math.random() * 100).toString();
    string = randomInt + string;
  }

  // Add random special characters
  const specialChars = "!@#$%^&*()_+[]{}|;:',.<>?";
  const specialCount = Math.floor(Math.random() * 4) + 1;
  let specialChar =
    specialChars[Math.floor(Math.random() * specialChars.length)];
  if (specialCount === 1) {
    string += specialChar;
  } else if (specialCount === 2) {
    string = specialChar + string;
  } else if (specialCount === 3) {
    const half = Math.floor(string.length / 2);
    string = string.substring(0, half) + specialChar + string.substring(half);
  } else {
    string += specialChar;
    specialChar = specialChars[Math.floor(Math.random() * specialChars.length)];
    string = specialChar + string;
  }

  return string;
}
