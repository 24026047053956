import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import {
  FaCheckDouble,
  FaChevronDown,
  FaClock,
  FaCopy,
  FaGear,
  FaSpinner,
} from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import { nanoid } from "nanoid";
import { axios } from "../Auth";

export default function PastEventTime() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState("");
  const [formData, setFormData] = useState({
    centre: "",
    date: "",
    service: "",
    password: "",
  });
  const [formDataBackup, setFormDataBackup] = useState({
    centre: "",
    date: "",
    service: "",
  });
  const [copied, setCopied] = useState(false);
  const { centres } = useOutletContext();

  const pastWeek = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10);
  const yesterday = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10);

  function timeCompare() {
    const past = new Date(pastWeek).getTime();
    const yest = new Date(yesterday).getTime();
    const date = new Date(formData.date).getTime();
    return date >= past && date <= yest;
  }

  const handleCopy = (link) => {
    const data = `${formDataBackup.centre} ${formDataBackup.service} for ${formDataBackup.date}\n\n${link}`;
    navigator.clipboard
      .writeText(data)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {});
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldData) => ({ ...oldData, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    setLink("");
    setFormDataBackup({
      centre: "",
      date: "",
      service: "",
      password: "",
    });
    try {
      setLoading(true);
      if (!timeCompare()) {
        setStatus({
          status: "error",
          type: "failed",
          message: "Date can only be between Yesterday and A Week Ago",
        });
        setLoading(false);
        return;
      }

      let formdata = new FormData();
      for (var key in formData) {
        formdata.append(key, formData[key]);
      }
      formdata.append("create_past_event", "wenwjekwmk");
      const url = "php/update.php";
      const send = await axios.post(url, formdata);
      const set = send.data;

      setTimeout(() => {
        if (typeof set === "object" && set.status === "Success") {
          setStatus({
            status: "success",
            type: "success",
            message: "Created Successfully",
          });
          const link =
            formData.centre.indexOf(" Workers") !== -1
              ? process.env.REACT_APP_WORKERS
              : process.env.REACT_APP_GENERAL;
          setLink(`${link}/signin/${set.token}`);
          setFormDataBackup(formData);
          setFormData({
            centre: "",
            date: "",
            service: "",
            password: "",
          });
        } else if (set === "Password") {
          setStatus({
            status: "password",
            type: "failed",
            message: "Incorrect Password",
          });
        } else if (set === "Exists") {
          setStatus({
            status: "error",
            type: "failed",
            message: "Centre Link Exists",
          });
        } else {
          setStatus({
            status: "error",
            type: "failed",
            message: "Creation Failed",
          });
        }
        setLoading(false);
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Creation Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  document.title = "Past Event Time";

  const centresArrayElement = Object.keys(centres)
    .sort()
    .map((centre) => (
      <option value={centre} key={nanoid()}>
        {centre}
      </option>
    ));

  const centresArrayElementWorkers = Object.keys(centres)
    .sort()
    .map((centre) => (
      <option value={centre + " Workers"} key={nanoid()}>
        {centre + " Workers"}
      </option>
    ));

  return (
    <>
      <div className="form-header overflow-y">
        <h1>Past Event Time</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => setStatus({ status: "", type: "", message: "" })}
              />
            </p>
          )}
          {status.status === "success" && link && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message failed`}>
                Links cannot be retrieved and will expire in 8hrs
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => setLink("")}
              />
            </p>
          )}
          {status.status === "success" && link && (
            <p
              className="status-wrapper"
              id="status-wrapper"
              onClick={() => (copied ? null : handleCopy(link))}
              style={{ cursor: "pointer" }}
            >
              <span className={`status-message ${copied && "success"}`}>
                {copied ? "Copied to Clipboard" : "Click to copy link"}
              </span>
              {!copied && (
                <FaCopy className="status-close" style={{ right: "30px" }} />
              )}
              {copied && (
                <FaCheckDouble
                  className="status-close"
                  style={{ color: "green", right: "30px" }}
                />
              )}
              <LiaTimesCircle
                className="status-close"
                onClick={() => setLink("")}
              />
            </p>
          )}
          <div className="form-group">
            <label htmlFor="centre">Worship Centre</label>
            <select
              disabled={loading}
              id="centre"
              onChange={handleChange}
              value={formData.centre}
              name="centre"
              required
              autoComplete="off"
            >
              <option disabled value="">
                Choose one
              </option>
              {centresArrayElement}
              {centresArrayElementWorkers}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              disabled={loading}
              id="date"
              onChange={handleChange}
              value={formData.date}
              min={pastWeek}
              max={yesterday}
              name="date"
              required
              onClick={(e) => e.target.showPicker()}
            />
            <FaClock className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="service">Service</label>
            <select
              disabled={loading}
              id="service"
              onChange={handleChange}
              value={formData.service}
              name="service"
              required
              autoComplete="off"
            >
              <option disabled value="">
                Choose one
              </option>
              <option value="1st Service">1st Service</option>
              <option value="2nd Service">2nd Service</option>
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              disabled={loading}
              type="password"
              onChange={handleChange}
              value={formData.password}
              id="password"
              name="password"
              required
              placeholder={"Password"}
            />
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinner" /> : "Create"}
            </button>
          </div>
          <div
            style={{
              marginTop: "-15px",
              marginBottom: "10px",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            <Link
              to={`events`}
              style={{
                color: "#072a46",
              }}
            >
              <FaGear /> Manage Past Event
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}
