import React from "react";
import { Link } from "react-router-dom";
import { nanoid } from "nanoid";
import { FaCaretRight } from "react-icons/fa6";

export default function Groups() {
  const groups = ["Centres", "Departments", "Faculties", "Hostels"];

  const groupsElement = groups.sort().map((grouping) => (
    <div key={nanoid()}>
      <span>{grouping}</span>
      <Link to={`/${grouping.toLowerCase()}`}>
        <FaCaretRight />
      </Link>
    </div>
  ));
  document.title = "Groups";

  return (
    <>
      <div className="form-header" style={{ marginBottom: "0" }}>
        <h1>Groups</h1>
      </div>
      <div className="departments-wrapper">{groupsElement}</div>
    </>
  );
}
