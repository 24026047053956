import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import { FaCaretRight, FaMagnifyingGlass } from "react-icons/fa6";
import { debounce, showSwal } from "../AppManager";
import { axios } from "../Auth";

export default function PastEvents() {
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    async function getPast() {
      let formdata = new FormData();
      formdata.append("read_past_event", "moijm099yu");
      const url = "php/read.php";
      const send = await axios.post(url, formdata);
      const set = send.data;

      if (typeof set === "object") {
        if (set.length === 0) {
          showSwal("error", "No Event");
          navigate("/time/past");
        } else {
          setEvents(set);
        }
      } else {
        navigate("/time/past");
      }
    }
    getPast();
  }, [navigate]);

  useEffect(() => {
    const outlet = document.getElementsByClassName("outlet")[0];
    const formHeader = document.getElementsByClassName("form-header")[0];
    const departmentsWrapper = document.getElementsByClassName(
      "departments-wrapper"
    )[0];
    const searchSpecial = document.getElementsByClassName(
      "form-group search-input"
    )[0];
    outlet.style.height = "75vh";

    function height() {
      const total = outlet.clientHeight - 80;
      const search = searchSpecial ? searchSpecial.clientHeight : 0;
      const header = formHeader ? formHeader.clientHeight : 0;
      const left = total - search - header;
      if (departmentsWrapper.clientHeight > left && departmentsWrapper) {
        departmentsWrapper.style.height = left + "px";
      } else {
        outlet.style.removeProperty("height");
      }
    }

    const debouncedHeight = debounce(height, 100);

    height();
    const resizeObserver = new ResizeObserver(debouncedHeight);
    resizeObserver.observe(outlet);
    resizeObserver.observe(departmentsWrapper);
    resizeObserver.observe(formHeader);

    return () => {
      resizeObserver.disconnect();
      outlet.style.removeProperty("height");
      clearTimeout(debouncedHeight.timeout);
    };
  }, []);

  function handleSearch(e) {
    function cleanString(input) {
      // Regular expression to match only letters (both cases), numbers, and spaces
      const regex = /[^a-zA-Z0-9 ]/g;
      // Replace any character that is not a letter, number, or space with an empty string
      return input.replace(regex, "");
    }

    setSearch(cleanString(e.target.value));
  }

  const elements = events
    .filter(
      ({ centre, service }) =>
        centre.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        service.toLowerCase().indexOf(search.toLowerCase()) !== -1
    )
    .map(({ centre }) => (
      <div key={nanoid()}>
        <span>{centre}</span>
        <Link to={`${centre}`}>
          <FaCaretRight />
        </Link>
      </div>
    ));
  document.title = "Past Events";

  return (
    <>
      <div className="form-header" style={{ marginBottom: "0" }}>
        <h1>Past Events</h1>
      </div>
      {events.length > 5 && (
        <div
          className="form-group search-input"
          style={{ marginBottom: "5px", marginTop: 0 }}
        >
          <FaMagnifyingGlass />
          <input
            type="text"
            id="key"
            name="key"
            required
            value={search}
            onChange={handleSearch}
            maxLength={20}
            placeholder="Search"
          />
        </div>
      )}
      <div className="departments-wrapper">{elements}</div>
    </>
  );
}
