import React, { useEffect } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { nanoid } from "nanoid";
import { FaPlus } from "react-icons/fa6";

export default function Blocks() {
  const { hostels } = useOutletContext();
  const { gender, hostel } = useParams();
  const navigate = useNavigate();

  const hosts = hostels.filter((hos) => hos.hostel === hostel);

  useEffect(() => {
    if (hostels.length !== 0 && hosts.length === 0) {
      navigate(`/hostels/${gender}`);
    }
  }, [navigate, hostels, hosts.length, gender]);

  document.title = hostel;

  const blocksElement = hosts
    .filter(
      ({ block, room, brange, rrange }) => block && room && brange && rrange
    )
    .map((host) => (
      <div key={nanoid()}>
        <span>
          {host.block}
          {host.brange && ` ${host.brange}`}, {host.room} {host.rrange}
        </span>
      </div>
    ));

  return (
    <>
      <div className="form-header" style={{ marginBottom: "0" }}>
        <h1>{hostel}</h1>
      </div>
      <div className="departments-wrapper">
        <div>
          <span>Add Block / Room</span>
          <Link to={"add"}>
            <FaPlus />
          </Link>
        </div>
        {blocksElement}
      </div>
    </>
  );
}
