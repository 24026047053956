import { useEffect } from "react";
import { deepEqual } from "../AppManager";

export default function useRefetch(fetcherFunction, setData) {
  useEffect(() => {
    let timeout;
    async function fetcher(previous = []) {
      const result = await fetcherFunction();
      !deepEqual(result, previous) && setData(result);
      timeout = setTimeout(() => {
        fetcher(result);
      }, 5000);
    }
    fetcher();
    return () => {
      clearTimeout(timeout);
    };
  }, [fetcherFunction, setData]);
}
