import React, { useEffect, useState } from "react";
import { FaChevronDown, FaSpinner } from "react-icons/fa6";
import {
  addBlockRoom,
  combineRanges,
  isRangeSubset,
  isValidRange,
} from "../AppManager";
import { LiaTimesCircle } from "react-icons/lia";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { nanoid } from "nanoid";

export default function AddBlockRoom() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    block: "",
    newblock: "",
    brange: "",
    room: "",
    rrange: "",
  });
  const { hostels } = useOutletContext();
  const { gender, hostel } = useParams();
  const navigate = useNavigate();

  const blocks = {};

  let hosts = hostels
    .filter((hos) => hos.gender === gender && hos.hostel === hostel)
    .map(({ hostel, block, brange, room, rrange }) => {
      if (!blocks.hasOwnProperty(`${block} _ ${brange}`)) {
        blocks[`${block} _ ${brange}`] = [];
      }
      blocks[`${block} _ ${brange}`] = [
        ...blocks[`${block} _ ${brange}`],
        `${room} _ ${rrange}`,
      ];
      blocks[`${block} _ ${brange}`] = Array.from(
        new Set(blocks[`${block} _ ${brange}`])
      ).sort();
      return hostel;
    });
  hosts = Array.from(new Set(hosts));

  useEffect(() => {
    if (hostels.length !== 0 && hosts.length === 0) {
      navigate(`/hostels/${gender}`);
    }
  }, [navigate, hostels, hosts.length, gender]);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);

      if (!isValidRange(formData.brange)) {
        setStatus({
          status: "invalid",
          type: "failed",
          message: "Invalid Block Range",
        });
        setLoading(false);
        return;
      }

      if (!isValidRange(formData.rrange)) {
        setStatus({
          status: "invalid",
          type: "failed",
          message: "Invalid Room Range",
        });
        setLoading(false);
        return;
      }

      const data = {
        ...formData,
        hostel,
        gender,
        block: formData.block === "New" ? formData.newblock : formData.block,
      };

      const bunique =
        formData.block === "New"
          ? Object.keys(blocks).filter(
              (block) =>
                block.split(" _ ")[0].toLowerCase() ===
                data.block.toLowerCase().trim()
            )
          : [formData.block];

      if (bunique.length !== 0) {
        const brun = bunique[0];
        const bloldrange = brun.split(" _ ")[1];
        const bexists = isRangeSubset(data.brange, bloldrange);
        data.block = brun.split(" _ ")[0].trim();
        data.brange = combineRanges(data.brange, bloldrange);

        const burange = blocks[brun];
        const runique = burange.filter(
          (room) =>
            room.split(" _ ")[0].toLowerCase() ===
            data.room.toLowerCase().trim()
        );

        if (runique.length !== 0) {
          const oldrange = runique[0].split(" _ ")[1];
          data.rrange = combineRanges(data.rrange, oldrange);
          if (bexists) {
            const exists = isRangeSubset(data.rrange, oldrange);
            if (exists) {
              setTimeout(() => {
                setStatus({
                  status: "Exists",
                  type: "failed",
                  message: "Block / Room already Exists",
                });
                setLoading(false);
              }, 1000);
              return;
            }
          }
        }
      }

      let check =
        data.brange.indexOf("-") !== -1 && data.brange.indexOf(" - ") === -1;
      if (check) {
        data.brange = data.brange.replaceAll("-", " - ");
      }
      let rcheck =
        data.rrange.indexOf("-") !== -1 && data.rrange.indexOf(" - ") === -1;
      if (rcheck) {
        data.rrange = data.rrange.replaceAll("-", " - ");
      }

      const res = await addBlockRoom(data);
      if (res === "Success") {
        setTimeout(() => {
          setFormData({
            block: "",
            newblock: "",
            brange: "",
            room: "",
            rrange: "",
          });
          setStatus({
            status: "success",
            type: "success",
            message: "Added Successfully. Redirecting...",
          });
          setTimeout(() => {
            navigate(`/hostels/${gender}/${hostel}`);
          }, 1000);
        }, 2000);
      } else {
        setTimeout(() => {
          setStatus({
            status: "error",
            type: "failed",
            message: "Failed",
          });
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  document.title = "Add Block / Room";

  return (
    <>
      <div className="form-header overflow-y">
        <h1>Add Block / Room</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="form-group">
            <label htmlFor="hostel">Hostel</label>
            <input
              disabled={loading}
              type="text"
              value={hostel}
              id="hostel"
              name="hostel"
              pattern="[A-Za-z0-9 ]+"
              required
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="Block">Block</label>
            <select
              disabled={loading}
              onChange={handleChange}
              value={formData.block}
              name="block"
              id="block"
              required
            >
              <option disabled value="">
                Choose one...
              </option>
              <option value="New">New Block</option>
              {Object.keys(blocks)
                .filter((block) => block.trim() !== "_")
                .map((block) => (
                  <option key={nanoid()} value={block}>
                    {block.replace(" _ ", " ")}
                  </option>
                ))}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          {formData.block === "New" && (
            <div className="form-group">
              <label htmlFor="newblock">New Block</label>
              <input
                disabled={loading}
                type="text"
                onChange={handleChange}
                value={formData.newblock}
                id="newblock"
                name="newblock"
                required
                placeholder="e.g. Block / Guest Chalet"
                pattern="[A-Za-z0-9 ]+"
                maxLength={50}
                minLength={3}
                autoComplete="off"
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="brange">Block Range</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.brange}
              id="brange"
              name="brange"
              placeholder="e.g. 1, 3 - 12, 15"
              maxLength={100}
              minLength={1}
              required
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <label htmlFor="room">Room</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.room}
              id="room"
              name="room"
              required
              placeholder="e.g. Room / Common Room"
              pattern="[A-Za-z0-9 ]+"
              maxLength={50}
              minLength={3}
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <label htmlFor="rrange">Room Range</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.rrange}
              id="rrange"
              name="rrange"
              placeholder="e.g. 1, 3 - 12, 15"
              required
              maxLength={100}
              minLength={1}
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinner" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
