import React, { useEffect, useRef, useState } from "react";
import { FaChevronDown, FaSpinner } from "react-icons/fa6";
import {
  checkImage,
  cleanMatric,
  cleanName,
  isRangeSubset,
  rangeStringToArray,
  stripSpecialChar,
  updateStudent,
} from "../AppManager";
import { LiaCheckCircle, LiaTimesCircle } from "react-icons/lia";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { nanoid } from "nanoid";
import { generalURL } from "../Auth";

export default function Edit() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [error, setError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    matric: "",
    department: "",
    level: "",
    hostel: "",
    block: "",
    room: "",
    image: "",
  });
  const [member, setMember] = useState({
    name: "",
    matric: "",
    department: "",
    level: "",
    image: "",
  });
  const [uprogress, setUprogess] = useState(0);
  const [ustate, setUstate] = useState("");
  const [maxLevel, setMaxLevel] = useState(0);
  const { departments, hostels } = useOutletContext();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { matric } = useParams();
  const fileInputRef = useRef(null);

  let hosts = hostels
    .filter((hos) => formData.gender === hos.gender)
    .map(({ hostel }) => hostel);
  hosts = Array.from(new Set(hosts));

  let blocks = [];
  hostels
    .filter(
      (hos) => formData.hostel === hos.hostel && formData.gender === hos.gender
    )
    .map(({ block, brange }) => {
      const range = rangeStringToArray(brange);
      range.map((rg) => {
        blocks.push(`${block} ${rg}`);
        return rg;
      });
      return block;
    });
  blocks = Array.from(new Set(blocks));

  let rooms = [];
  hostels
    .filter(
      (hos) =>
        formData.hostel === hos.hostel &&
        formData.block.split(" ")[0] === hos.block &&
        isRangeSubset(formData.block.split(" ")[1], hos.brange) &&
        formData.gender === hos.gender
    )
    .map(({ room, rrange }) => {
      const range = rangeStringToArray(rrange);
      range.map((rg) => {
        rooms.push(`${room} ${rg}`);
        return rg;
      });
      return room;
    });
  rooms = Array.from(new Set(rooms));

  useEffect(() => {
    if (
      state &&
      state.permission &&
      state.member &&
      state.id &&
      state.member.idnum === state.id &&
      matric === state.id
    ) {
      setMember(state.member);
      setFormData({
        ...state.member,
        image: "",
      });
      const selected = departments.filter(
        (dept) => dept.programme === state.member.department
      );

      selected[0] && setMaxLevel(selected[0].maxlevel);
    } else {
      navigate("/edit");
    }
  }, [navigate, state, matric, departments]);

  function handleChange(e) {
    const { name, type, value, files } = e.target;
    const maxSize = 50;
    if (type === "file" && checkImage(files, maxSize, setError, setStatus)) {
      setFormData((oldData) => ({ ...oldData, [name]: files[0] }));
    } else {
      setFormData((oldData) => ({
        ...oldData,
        [name]:
          name === "name"
            ? cleanName(value)
            : name === "matric"
            ? cleanMatric(value)
            : value,
      }));
    }
    if (name === "department") {
      const selected = departments.filter((dept) => dept.programme === value);
      setMaxLevel(selected[0].maxlevel);
      setFormData((oldData) => ({ ...oldData, level: "" }));
    } else if (name === "gender") {
      setFormData((old) => ({ ...old, hostel: "", block: "", room: "" }));
    } else if (name === "hostel") {
      setFormData((old) => ({ ...old, block: "", room: "" }));
    } else if (name === "block") {
      setFormData((old) => ({ ...old, room: "" }));
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setUploading(true);
      const downloadURL =
        typeof formData.image === "object"
          ? stripSpecialChar(formData["matric"]) +
            formData["image"].name.slice(
              formData["image"].name.lastIndexOf(".")
            )
          : member["image"];
      const data = {
        ...formData,
        oldmatric: member.matric,
        oldidnum: member.idnum,
      };
      const res = await updateStudent(data);

      if (res === "Updated") {
        setMember({
          ...formData,
          image: downloadURL,
        });
        if (typeof formData.image === "object") {
          setUstate("Uploading...");
          const interv = setInterval(() => {
            setUprogess((oldProgress) => {
              if (oldProgress >= 100) {
                clearInterval(interv);
              }
              const rand = Math.floor(Math.random() * 25 + 15);
              const add = oldProgress + rand;
              return add > 100 ? 100 : add;
            });
          }, 500);
        }
        setTimeout(
          () => {
            setFormData({
              image: "",
              name: "",
              matric: "",
              department: "",
              level: "",
              hostel: "",
              block: "",
              room: "",
            });
            fileInputRef.current.value = null;
            setStatus({
              status: "success",
              type: "success",
              message: "Updated Successfully. Redirecting...",
            });
            setTimeout(() => {
              navigate("/edit", { replace: true });
            }, 2000);
          },
          typeof formData.image === "object" ? 3000 : 1000
        );
      } else if (res === "Exists") {
        setStatus({
          status: "Exists",
          type: "failed",
          message: "New Matric already Exists",
        });
        setUploading(false);
      } else if (res === "Not Found") {
        setStatus({
          status: "not found",
          type: "failed",
          message: "Student not Found. Redirecting...",
        });
        setTimeout(() => {
          navigate("/edit", { replace: true });
        }, 2000);
      } else {
        setTimeout(() => {
          setStatus({
            status: "error",
            type: "failed",
            message: "Update Failed",
          });
          setUploading(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Update Failed",
        });
        setUploading(false);
      }, 1000);
    }
  }

  const departmentElement = departments.map((dept) => (
    <option value={dept.programme} key={nanoid()}>
      {dept.programme}
    </option>
  ));

  const levelElements = [];

  if (maxLevel !== 0) {
    for (let i = 100; i <= maxLevel; i += 100) {
      levelElements.push(
        <option value={i} key={nanoid()}>
          {i}
        </option>
      );
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  document.title = "Edit Student";

  return (
    <>
      <div className="form-header overflow-y">
        <h1>Edit Student</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="edit-group">
            <div>
              <h5 className="text-center fw-bolder">Current Details</h5>
            </div>
            <div className="edit-details">
              <div className="imgwrap">
                <img
                  onError={(e) => {
                    if (e.target.src !== "/user.png") {
                      e.target.onerror = null;
                      e.target.src = "/user.png";
                    }
                  }}
                  className="img"
                  src={`${generalURL}/images/students/${member.image}`}
                  alt={member.name}
                />
              </div>
              <div className="edit-details-text">
                <p>{member.name}</p>
                <p>
                  {member.gender === "M" && "Male"}
                  {member.gender === "F" && "Female"}
                </p>
                <p>{member.matric}</p>
                <p>{member.department}</p>
                <p>{member.level} Level</p>
                <p>{member.hostel}</p>
                <p>
                  {member.block}, {member.room}
                </p>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              disabled={uploading}
              type="text"
              onChange={handleChange}
              value={formData.name}
              id="name"
              name="name"
              required
              placeholder="Name"
              maxLength={30}
              minLength={7}
            />
          </div>
          <div className="form-group">
            <label htmlFor="matric">Matric Number</label>
            <input
              disabled={uploading}
              type="text"
              onChange={handleChange}
              value={formData.matric}
              id="matric"
              name="matric"
              required
              placeholder="Matric Number"
              pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/[A-Za-z]{3}(-[A-Za-z])?/\d{2}/\d{4,5}$|(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/(FD|fd|Fd|fD)/\d{2}/\d{4,5}$|\d{12}[A-Za-z]{2}|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$"
              maxLength={18}
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <label htmlFor="gender">Gender</label>
            <select
              disabled={uploading}
              onChange={handleChange}
              value={formData.gender}
              name="gender"
              id="gender"
              required
            >
              <option disabled value="">
                Choose one...
              </option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="department">Department</label>
            <select
              disabled={uploading}
              onChange={handleChange}
              value={formData.department}
              name="department"
              id="department"
              required
            >
              <option disabled value="">
                Choose one
              </option>
              {departmentElement}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="level">Level</label>
            <select
              disabled={uploading}
              onChange={handleChange}
              value={formData.level}
              id="level"
              name="level"
              required
            >
              <option disabled value="">
                Choose one
              </option>
              {!formData.department && (
                <option disabled value=" ">
                  Choose Department First
                </option>
              )}
              {levelElements}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="hostel">Hostel</label>
            <select
              disabled={uploading}
              onChange={handleChange}
              value={formData.hostel}
              name="hostel"
              id="hostel"
              required
            >
              <option disabled value="">
                Choose one...
              </option>
              {!formData.gender && (
                <option disabled>Choose Gender First</option>
              )}
              {hosts.map((hostel) => (
                <option key={nanoid()} value={hostel}>
                  {hostel}
                </option>
              ))}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="block">Block</label>
            <select
              disabled={uploading}
              onChange={handleChange}
              value={formData.block}
              name="block"
              id="block"
              required
            >
              <option disabled value="">
                Choose one...
              </option>
              {!formData.hostel && (
                <option disabled>Choose Hostel First</option>
              )}
              {blocks.map((block) => (
                <option key={nanoid()} value={block}>
                  {block}
                </option>
              ))}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="room">Room</label>
            <select
              disabled={uploading}
              onChange={handleChange}
              value={formData.room}
              name="room"
              id="room"
              required
            >
              <option disabled value="">
                Choose one...
              </option>
              {!formData.block && <option disabled>Choose Block First</option>}
              {rooms.map((room) => (
                <option key={nanoid()} value={room}>
                  {room}
                </option>
              ))}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="image">Image</label>
            <input
              disabled={uploading}
              type="file"
              accept="image/jpg, image/jpeg image/png image/heic"
              onChange={handleChange}
              id="image"
              name="image"
              ref={fileInputRef}
            />
          </div>
          <div className="form-group">
            <button type="submit" disabled={uploading || error}>
              {uploading ? (
                uprogress === 100 ? (
                  "Finishing..."
                ) : (
                  <FaSpinner className="spinner" />
                )
              ) : (
                "Submit"
              )}
            </button>
            {uploading && uprogress !== 0 && (
              <div className="upload-status">
                <div id="upload-progress">
                  <div
                    id="upload-progress-bar"
                    className="loader-item"
                    style={{
                      width: uprogress + "%",
                      backgroundImage:
                        uprogress !== 100
                          ? "linear-gradient(110deg, #072a46 8%, #1257a7 18%, #072a46 33%)"
                          : "linear-gradient(110deg, green 8%, yellowgreen 18%, green 33%)",
                    }}
                  ></div>
                  <label>{Math.floor(uprogress)}%</label>
                </div>
                <div>
                  {uprogress === 100 ? (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <LiaCheckCircle
                        style={{
                          fontSize: "1.2em",
                          marginRight: "2px",
                          color: "green",
                        }}
                      />
                      Upload Completed
                    </span>
                  ) : (
                    ustate
                  )}
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
